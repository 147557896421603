import { defineVideoOptions } from "@components/ContentfulComponents/ComponentAsset/utils/asset.utils";
import { IVideo } from "@components/ContentfulComponents/ComponentAsset/Video/Video.component";

interface IYoutubeVideo extends IVideo {
	videoTitle?: string | null;
}

const YoutubeVideo = ({ assetUrl, videoOptions, className, videoTitle }: IYoutubeVideo) => {
	if (!assetUrl || !videoTitle) return null;

	const { autoplayVideo, showControls } = defineVideoOptions(videoOptions);

	const normalizedUrl = assetUrl.replace("watch?v=", "embed/");
	const autoPlay = autoplayVideo ? "?autoplay=1" : "?autoplay=0";
	const controls = showControls ? "" : "&controls=0";

	return (
		<iframe
			className={className ?? ""}
			src={`${normalizedUrl}${autoPlay}${controls}&mute=1`}
			title={videoTitle}
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
			data-testid="YoutubeVideo_wrapper"
		/>
	);
};

export default YoutubeVideo;
