import { EDataLayerEventType, IDataLayerEventSearchSuggestion } from "../datalayer.types";

export const buildSearchSuggestionDataLayer = ({
	term,
	count,
	type,
	url,
}: IDataLayerEventSearchSuggestion) => {
	return {
		search: {
			suggestion: {
				term,
				results: count,
				clicked_result_type: type,
				clicked_result_link: url,
			},
		},
		event: EDataLayerEventType.SearchSuggestion,
	};
};
