import useGraphQL from "@hooks/useGraphql";

import {
	EFooterLinksIdentifiers,
	footerLinksQuery,
	IFooterLinksVariables,
	TFooterLinksData,
} from "./FooterLinks.query";
import FooterLinksGroup from "./FooterLinksGroup.component";

import styles from "./FooterLinks.module.scss";

const FooterLinks = () => {
	const { data } = useGraphQL<TFooterLinksData, IFooterLinksVariables>(footerLinksQuery, {
		variables: { identifier: EFooterLinksIdentifiers.BlockFooterLinks },
	});

	if (!data) return null;

	return (
		<div className={styles.footerLinksWrapper} data-testid="FooterLinks_wrapper">
			<hr className={styles.divider} />

			{data.contentBlockCollection?.items?.[0]?.contentCollection?.items?.map((item) => {
				return <FooterLinksGroup groupLinks={item} key={item?.sys?.id} />;
			})}
		</div>
	);
};

export default FooterLinks;
