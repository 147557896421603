import { ILinkCflData } from "@components/ContentfulComponents/ComponentLink/type";
import { useAbsolute } from "@hooks/useAbsolute";
import { convertRelOptions, convertTargetOptions } from "@shared/contentful/contentful.utils";

interface ISimpleLink {
	link: ILinkCflData;
	testid: string;
	className?: string;
}

const SimpleLink = ({ link, testid, className }: ISimpleLink) => {
	const { absoluteUrl } = useAbsolute(link?.url || "");
	if (!absoluteUrl) return null;

	const { label, relOptions, targetOptions } = link;
	const rel = convertRelOptions(relOptions);
	const target = convertTargetOptions(targetOptions);

	return (
		<a href={absoluteUrl} target={target} rel={rel} className={className} data-testid={testid}>
			{label}
		</a>
	);
};

export default SimpleLink;
