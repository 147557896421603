import { useEffect, useState } from "react";

import useGraphQL from "@hooks/useGraphql";
import {
	EBannerIdentifier,
	headerBannerQuery,
	IHeaderBannerQueryVariables,
	THeaderBannerData,
} from "./headerBanner.query";

import styles from "./HeaderBanner.module.scss";
import RichText from "@components/ContentfulComponents/ComponentRichText/RichText.component";

const HeaderBanner = () => {
	const [activeIndex, setActiveIndex] = useState(0);

	const { data } = useGraphQL<THeaderBannerData, IHeaderBannerQueryVariables>(headerBannerQuery, {
		variables: { identifier: EBannerIdentifier.BlockBannerHeader },
	});

	const bannerTranslations = data?.contentBlockCollection?.items?.[0]?.contentCollection?.items;

	useEffect(() => {
		if (bannerTranslations && bannerTranslations.length > 1) {
			const interval = setInterval(() => {
				if (activeIndex === bannerTranslations.length - 1) setActiveIndex(0);
				else setActiveIndex(activeIndex + 1);
			}, 8000);

			return () => {
				if (interval) clearInterval(interval);
			};
		}
	}, [bannerTranslations, activeIndex]);

	if (!bannerTranslations?.length) return null;

	return (
		<div className={styles.textSliderWrapper} data-testid="HeaderBanner_wrapper">
			<span
				className={bannerTranslations?.length > 1 ? styles.text : ""}
				data-testid="HeaderBanner_text"
			>
				<RichText data={bannerTranslations[activeIndex]} />
			</span>
		</div>
	);
};

export default HeaderBanner;
