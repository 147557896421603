import React, { useEffect, useState } from "react";
import { LogOut } from "react-feather";

import Button from "@components/Button/Button.component";
import { EButtonSize, EButtonType } from "@components/Button/types";

import useGraphQL from "@hooks/useGraphql";

import { getContentTranslations } from "@shared/contentful/translations.utils";
import { TContentTranslation } from "@shared/types/translations.types";

import {
	EPreviewIdentifiers,
	IPreviewVariables,
	previewQuery,
	TPreviewContentData,
} from "./preview.query";

import styles from "./preview.module.scss";

const Preview = () => {
	const [translations, setTranslations] = useState<TContentTranslation<string>>({});
	const { data } = useGraphQL<TPreviewContentData, IPreviewVariables>(previewQuery, {
		variables: {
			identifier: EPreviewIdentifiers.BlockPreviewContent,
		},
	});

	useEffect(() => {
		if (data?.contentBlockCollection?.items?.[0]?.contentCollection?.items) {
			setTranslations(
				getContentTranslations(data.contentBlockCollection.items[0].contentCollection.items)
			);
		}
	}, [data]);

	const title = translations?.[EPreviewIdentifiers.TranslationTitle];
	const linkLabel = translations?.[EPreviewIdentifiers.TranslationLinkLabel];

	if (!title && !linkLabel) {
		return null;
	}

	return (
		<div className={styles.wrapper} data-testid="Preview_wrapper">
			{title && (
				<p className={styles.text} data-testid="Preview_text">
					{title}
				</p>
			)}
			{linkLabel && (
				<Button
					as="a"
					href={"/api/preview/exit-preview"}
					aria-label={linkLabel}
					buttonType={EButtonType.PrimaryLight}
					buttonSize={EButtonSize.Sm}
				>
					<LogOut /> {linkLabel}
				</Button>
			)}
		</div>
	);
};

export default Preview;
