import { componentLinkWithIconFragment } from "@components/ContentfulComponents/ComponentLink/fragment";
import { componentTextWithLinkFragment } from "@components/ContentfulComponents/ComponentTextWithLink/fragment";
import { ITextWithLinkCflData } from "@components/ContentfulComponents/ComponentTextWithLink/type";
import { IContentBlockCollection } from "@shared/contentful/contentful.types";
import { gql } from "graphql-request";

export enum EPromiseIdentifier {
	BlockFooterPromises = "block_footer-promises",
}

export interface IPromiseQueryIdentifiers {
	identifier: EPromiseIdentifier;
}

export type TPromisesQueryDaya = IContentBlockCollection<ITextWithLinkCflData>;

export const promisesQuery = gql`
	${componentTextWithLinkFragment}
	${componentLinkWithIconFragment}
	query promises($identifier: String, $locale: String) {
		contentBlockCollection(where: { identifier: $identifier }, locale: $locale, limit: 1) {
			items {
				... on ContentBlock {
					__typename
					contentCollection {
						items {
							...textWithLink
						}
					}
				}
			}
		}
	}
`;
