import { useEffect, useRef } from "react";

export default function useHandleClosePortal<T extends Element>(toggleFn: () => void) {
	const ref = useRef<T>(null);

	const handleExitWithEscape = (event: KeyboardEvent) => {
		if (event.key === "Escape" && ref.current) {
			toggleFn();
		}
	};

	const handleClickOutside = (event: Event) => {
		if (ref.current && !ref.current.contains(event.target as Node)) {
			toggleFn();
		}
	};

	useEffect(() => {
		document.addEventListener("keydown", handleExitWithEscape, true);
		document.addEventListener("click", handleClickOutside, true);
		return () => {
			document.removeEventListener("keydown", handleExitWithEscape, true);
			document.removeEventListener("click", handleClickOutside, true);
		};
	});

	return ref;
}
