import React, { createContext, useContext, useRef, useState } from "react";
import { useRouter } from "next/router";
import HeaderBanner from "@components/Header/Components/HeaderBanner/HeaderBanner.component";
import ButtonMenu from "@components/Header/Components/ButtonMenu/ButtonMenu.component";
import Logo from "@components/Header/Components/Logo/Logo.component";
import Cart from "@components/Header/Components/Cart/Cart.component";
import Search from "@components/Header/Components/Search/Search.component";
import Lang from "@components/Header/Components/Lang/Lang.component";
import Store from "@components/Header/Components/Store/Store.component";
import Account from "@components/Header/Components/Account/Account.component";
import MenuHeader from "@components/Header/Components/MenuHeader/MenuHeader.component";
import { menuFirstQuery } from "./menu.query";
import {
	EMenuFirstQueryIdentifier,
	IDisplayMenu,
	IMenuFirstQueryIdentifier,
	IMenuGQL,
} from "@components/Menu/menu.types";
import { IMenuHeaderCflData } from "@components/ContentfulComponents/BlockMegaMenu/type";
import { LayoutContext } from "@components/Layout/Layout.context";
import { useScroll, useScrollTop } from "@hooks/useScroll";
import useGraphQL from "@hooks/useGraphql";
import { useResponsive } from "@hooks/useDevice";
import styles from "@components/Header/Header.module.scss";
import PostalCodePicker from "@components/Geolocation/PostalCodePicker/PostalCodePicker.component";
import { TSetDisplayMenu } from "../Components/type";

export const MenuContext = createContext<TSetDisplayMenu>(() => ({}));

interface ISetClassName {
	current: string;
	isShowMenuTop: boolean;
	isScrollTop: boolean;
}

const CART_PATHNAME = "/cart";

export const setClassName = ({ current, isShowMenuTop, isScrollTop }: ISetClassName) => {
	if (isScrollTop && isShowMenuTop) {
		return styles.headerSticky;
	}

	if (!isScrollTop && isShowMenuTop && current === styles.headerSticky) {
		return styles.headerHidden;
	}
};

const Header = () => {
	const { pathname } = useRouter();
	const isShowMenuTop = useScroll(5);
	const isScrollTop = useScrollTop();
	const className = useRef(styles.headerInitial);
	const { searchBarFocused } = useContext(LayoutContext);
	const [displayMenu, setDisplayMenu] = useState<IDisplayMenu>({ isDisplay: false });
	const { isMobile, isTablet } = useResponsive();
	const isSmallScreen = isMobile || isTablet;

	const { data } = useGraphQL<IMenuGQL, IMenuFirstQueryIdentifier>(menuFirstQuery, {
		variables: {
			identifier: EMenuFirstQueryIdentifier.MenuMainMenu,
			footerIdentifier: EMenuFirstQueryIdentifier.MenuContentFooterMenu,
		},
	});

	if (!data) return null;

	className.current =
		setClassName({ current: className.current, isShowMenuTop, isScrollTop }) ?? "";

	const menuData = data.megaMenuCollection?.items[0]?.contentCollection.items;
	const footerData = data.footerMenuCollection?.items[0]?.contentCollection.items;
	const menuHeader = menuData?.[0].contentCollection.items;
	const menu = { menuData, footerData };
	const isCartScreen = pathname === CART_PATHNAME;

	return (
		<>
			{isSmallScreen && searchBarFocused ? null : <HeaderBanner />}
			<header className={`${styles.headerPage} ${className.current}`} data-testid="Header_wrapper">
				<div
					className={`container-grid large-grid ${styles.wrapperElement} ${
						searchBarFocused && styles.searchBarFocusedWrapperElement
					}`}
				>
					{isSmallScreen && searchBarFocused ? null : (
						<>
							<ButtonMenu
								className={styles.headerMenu}
								menu={menu}
								toggleMenu={setDisplayMenu}
								displayMenu={displayMenu}
							/>
							<div className={styles.headerLogo}>
								<Logo />
							</div>
						</>
					)}

					<div className={styles.headerSearch}>
						{isSmallScreen && !searchBarFocused && !isCartScreen && <PostalCodePicker />}
						<Search />
					</div>
					<div className={`${styles.wrapperButtons} ${searchBarFocused ? styles.hideIcons : ""}`}>
						<Lang className={styles.hiddenMobile} />
						<Store className={styles.hiddenMobile} />
						<Account className={styles.hiddenMobile} />
						<Cart />
					</div>
					{menuHeader && (
						<MenuContext.Provider value={setDisplayMenu}>
							<MenuHeader
								data={menuHeader as IMenuHeaderCflData[]}
								className={styles.horizontalMenu}
								isCartScreen={isCartScreen}
							/>
						</MenuContext.Provider>
					)}
					{pathname !== "/" && <hr className={styles.divider} />}
				</div>
			</header>
		</>
	);
};

export default Header;
