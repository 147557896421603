import React from "react";
import { IPriceData, IProduct } from "@shared/types/product.types";
import styles from "./SearchProductInfo.module.scss";
import ProductPrice from "@components/ProductInfo/ProductPrice/ProductPrice.component";

export type TProductInfo = Pick<IProduct, "brand" | "name"> & {
	linkToProduct: string;
	price: IPriceData;
	className: string;
	handleClick?: () => void;
};

const SearchProductInfo = ({
	price,
	brand,
	name,
	linkToProduct,
	className,
	handleClick,
}: TProductInfo) => {
	return (
		<a
			href={linkToProduct}
			className={styles[className]}
			onClick={handleClick}
			data-testid="SearchProductInfo_link"
		>
			<p className={styles.productName}>{name}</p>
			<p className={styles.brand}>{brand}</p>
			<ProductPrice price={price} className={styles[className]} />
		</a>
	);
};

export default SearchProductInfo;
