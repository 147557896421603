import useGraphQL from "@hooks/useGraphql";

import {
	ENewMemberLinkIdentifier,
	newMemberLinkQuery,
	INewMemberLinkVariables,
	TNewMemberLinkData,
} from "./NewMemberLink.query";

import styles from "./NewMemberLink.module.scss";

import { convertRelOptions, convertTargetOptions } from "@shared/contentful/contentful.utils";
import { replaceAllBreakSpaces } from "@shared/text/text.utils";
import { useAbsolute } from "@hooks/useAbsolute";

const NewMemberLink = () => {
	const { data } = useGraphQL<TNewMemberLinkData, INewMemberLinkVariables>(newMemberLinkQuery, {
		variables: { identifier: ENewMemberLinkIdentifier.newMemberLink },
	});
	const url = data?.linkCollection?.items?.[0]?.url;
	const { absoluteUrl } = useAbsolute(url ?? "");

	if (!url) return null;

	const { label, relOptions, targetOptions } = data.linkCollection.items[0];

	const rel = convertRelOptions(relOptions);
	const target = convertTargetOptions(targetOptions);

	return (
		<a href={absoluteUrl} target={target} rel={rel} className={styles.newMember}>
			{replaceAllBreakSpaces(label)}
		</a>
	);
};

export default NewMemberLink;
