import { gql } from "graphql-request";
import { componentRichTextFragment } from "@components/ContentfulComponents/ComponentRichText/fragment";
import { IRichTextCflData } from "@components/ContentfulComponents/ComponentRichText/type";
import { IContentBlockCollection } from "@shared/contentful/contentful.types";

export enum EBannerIdentifier {
	BlockBannerHeader = "block_banner-header",
}

export type THeaderBannerData = IContentBlockCollection<IRichTextCflData>;

export interface IHeaderBannerQueryVariables {
	identifier: EBannerIdentifier;
}

export const headerBannerQuery = gql`
	${componentRichTextFragment}
	query headerBannerQuery($identifier: String, $locale: String!) {
		contentBlockCollection(where: { identifier: $identifier }, locale: $locale) {
			items {
				contentCollection(limit: 3) {
					items {
						...richText
					}
				}
			}
		}
	}
`;
