import { useEffect, useState } from "react";
import { getUserGeolocationFromCookies } from "@utils/geolocation.utils";
import { IUserGeolocation } from "@shared/types/geolocation.types";
import { selectMiscPostalCodeUpdateDate } from "@redux/misc/misc.selector";
import { useSelector } from "react-redux";
import defaultUserGeolocation from "@config/misc";

const useGeolocation = () => {
	const [geolocation, setGeolocation] = useState<IUserGeolocation>();
	const postalCodeUpdate = useSelector(selectMiscPostalCodeUpdateDate);

	useEffect(() => {
		(async () => {
			try {
				const userGeolocation = await getUserGeolocationFromCookies();
				setGeolocation(userGeolocation);
			} catch (error) {
				console.error(error);
				setGeolocation(defaultUserGeolocation);
			}
		})();
	}, [postalCodeUpdate]);

	return geolocation;
};

export default useGeolocation;
