import React, { ReactNode, useRef } from "react";
import { useRouter } from "next/router";
import axios from "axios";
import { isProtectedRoute, removeTrailingSlash } from "@utils/url.utils";
import { signOut } from "next-auth/react";
import { setRedirectURLInCookies } from "@shared/profile/cookies.utils";

export interface ILogoutProps {
	children: ReactNode;
	className?: string;
	overridenRedirectUrl?: string;
}

const Logout = ({ children, className, overridenRedirectUrl }: ILogoutProps) => {
	const { asPath, locale } = useRouter();
	const isLogoutCalled = useRef(false);

	const handleLogout = async () => {
		if (isLogoutCalled.current) return;

		isLogoutCalled.current = true;

		const { data } = await axios.get("/api/dktlogin/try-logout");

		const urlRedirect = overridenRedirectUrl || asPath;

		const urlInCookie = isProtectedRoute(urlRedirect)
			? `/${locale}`
			: `/${locale}${removeTrailingSlash(urlRedirect)}`;

		setRedirectURLInCookies(urlInCookie);

		await signOut({ redirect: false });
		const redirect = data?.logoutUrl || `/${locale}`;

		window.location.href = redirect;
	};

	return (
		<button className={className} onClick={handleLogout} data-testid="Logout_btn">
			{children}
		</button>
	);
};

export default Logout;
