import Promises from "@components/Promises/Promises.component";
import FooterLinks from "@components/FooterLinks/FooterLinks.component";
import FooterSEOText from "@components/FooterSEOText/FooterSEOText.component";
import SocialMedia from "@components/SocialMedia/SocialMedia.component";
import Legal from "@components/Legal/Legal.component";
import styles from "./Footer.module.scss";
import LineDivider from "@components/LineDivider/LineDivider.component";
import ScrollToTopMobile from "@components/ScrollToTop/ScrollToTopMobile.component";
import ScrollToTopDesktop from "@components/ScrollToTop/ScrollToTopDesktop.component";
import { memo } from "react";
import NewMemberLink from "@components/FooterLinks/NewMemberLink/NewMemberLink.component";
import { useResponsive } from "@hooks/useDevice";

interface IFooter {
	hideReinsurance?: boolean | null;
}

const Footer = ({ hideReinsurance }: IFooter) => {
	const { isMobile, isTablet } = useResponsive();
	const isSmallScreen = isMobile || isTablet;

	return (
		<footer className={styles.footer}>
			{!hideReinsurance && (
				<div className="container-grid medium-grid">
					<LineDivider />
					<Promises />
				</div>
			)}
			<ScrollToTopMobile />
			<ScrollToTopDesktop />
			<div className={styles.footerWrapper}>
				<div className="container-grid medium-grid">
					{isSmallScreen && (
						<div className={styles.lineDivider}>
							<LineDivider />
						</div>
					)}
					<div className={styles.seoBlock}>
						<FooterSEOText />
						<NewMemberLink />
					</div>
					<FooterLinks />
					<SocialMedia />
					<Legal />
				</div>
			</div>
		</footer>
	);
};

export default memo(Footer);
