import { useCallback, useEffect, useRef, useState } from "react";

type TCallBack = (state: boolean) => void;

export type TSetToggle = (callBack?: TCallBack) => void;

type ToggleReturn = [boolean, TSetToggle];

const useToggle = (isExpanded: boolean): ToggleReturn => {
	const [toggledState, setToggledState] = useState(isExpanded);
	const cbRef = useRef<TCallBack>();

	const eventHandler = useCallback((callBack?: TCallBack) => {
		cbRef.current = callBack;
		setToggledState((state) => !state);
	}, []);

	useEffect(() => {
		if (typeof cbRef.current === "function") {
			const cb = cbRef.current;
			setTimeout(() => {
				cb(toggledState);
			}, 1);
			cbRef.current = undefined;
		}
	}, [toggledState]);

	return [toggledState, eventHandler];
};

export default useToggle;
