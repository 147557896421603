import React from "react";

import { ILinkCflData } from "@components/ContentfulComponents/ComponentLink/type";

import useToggle from "@hooks/useToggle";

import FooterLinksTitleDesktop from "./FooterLinksTitleDesktop.component";
import FooterLinksTitleMobile from "./FooterLinksTitleMobile.component";
import FooterLinkLi from "./FooterLinkLi.component";

import styles from "./FooterLinks.module.scss";

interface IProps {
	links: ILinkCflData[];
	isSmallScreen: boolean;
	label?: string;
}

const ExpandableLinks = ({ links, isSmallScreen, label }: IProps) => {
	const [isExpanded, toggleExpanded] = useToggle(false);
	return (
		<>
			{isSmallScreen ? (
				<FooterLinksTitleMobile label={label} handler={toggleExpanded} />
			) : (
				<FooterLinksTitleDesktop label={label} />
			)}
			<ul
				data-testid="ExpandableLinks_list-wrapper"
				className={`${styles.expandableList} ${!isExpanded && isSmallScreen ? styles.closed : ""} `}
			>
				{links.map((link) => (
					<FooterLinkLi data={link} key={link.label} />
				))}
			</ul>
		</>
	);
};

export default ExpandableLinks;
