import React from "react";
import MenuItem from "../MenuItem/MenuItem.component";
import { ILevelProps, TMenuLevel } from "../menu.types";

import styles from "./MenuSection.module.scss";
import { IMenuHeaderCflData } from "@components/ContentfulComponents/BlockMegaMenu/type";
import { ILinkCflData } from "@components/ContentfulComponents/ComponentLink/type";

const ANIMATION_DELAY = 0.1;

const MenuSection = ({
	content,
	topHeaderContentLength,
	...rest
}: ILevelProps<TMenuLevel> & { topHeaderContentLength: number }) => {
	const getAnimationDelay = (item: ILinkCflData | IMenuHeaderCflData, index: number) => {
		if (rest.currentLevel === 1) {
			return "url" in item
				? `${topHeaderContentLength * ANIMATION_DELAY}s`
				: `${index * ANIMATION_DELAY}s`;
		}
		return `${ANIMATION_DELAY}s`;
	};
	return (
		<ul
			data-testid="MenuSection_level-section"
			className={`${styles.sectionWrapper} ${styles[rest.classNameLevel]}`}
		>
			{content.contentCollection.items.map((item, index) => (
				<MenuItem
					content={item}
					key={item.label}
					style={{
						animationDelay: getAnimationDelay(item, index),
					}}
					{...rest}
				/>
			))}
		</ul>
	);
};
export default MenuSection;
