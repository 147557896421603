import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IPropsComponent } from "../type";

import { ELayoutIdentifiers } from "@components/Layout/type";
import IconCart from "@components/Icon/IconCart/IconCart.component";
import { useAbsolute } from "@hooks/useAbsolute";
import { setItemsCount } from "@redux/cart/cart.reducer";
import { selectCartItemsCount } from "@redux/cart/cart.selector";
import { selectTranslationStore } from "@redux/translation/translation.selector";
import { manageCartItemsCount } from "@shared/cart/cart.utils";
import { getCartItemsCountInCookies, isCartItemsCountInCookies } from "@shared/cart/cookies.utils";

const Cart = (props: IPropsComponent) => {
	const cartCount = useSelector(selectCartItemsCount);
	const translations = useSelector(selectTranslationStore);
	const dispatch = useDispatch();
	const { absoluteUrl } = useAbsolute("cart");

	useEffect(() => {
		if (!isCartItemsCountInCookies()) {
			manageCartItemsCount(dispatch);
		} else {
			dispatch(setItemsCount(getCartItemsCountInCookies() || 0));
		}
	}, []);

	const ariaIconCart = translations[ELayoutIdentifiers.AriaIconCart] || "see my cart";

	return (
		<a
			href={absoluteUrl}
			rel="nofollow"
			data-testid="Cart_btn"
			aria-label={ariaIconCart}
			{...props}
		>
			<IconCart cartCount={cartCount} ariaLabel={ariaIconCart} />
		</a>
	);
};

export default Cart;
