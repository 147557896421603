import React from "react";

import { getPropsForImages } from "@components/CustomImage/custom-images.utils";
import CustomImage from "@components/CustomImage/CustomImage.component";
import { ICartArticleInfo, ICartLine } from "@shared/types/cart.types";

import styles from "./ProductThumbnail.module.scss";

export type TProductThumbnailProps = {
	thumbnailUrl?: string;
	linkToProduct: string;
	className?: string;
	isFirstCartElement: boolean;
	handleClick?: () => void;
} & Pick<ICartLine, "brand"> &
	Pick<ICartArticleInfo, "itemName">;

const ProductThumbnail = ({
	thumbnailUrl,
	itemName,
	brand,
	linkToProduct,
	className = "",
	isFirstCartElement,
	handleClick,
}: TProductThumbnailProps) => {
	const mobileProps = getPropsForImages({
		image: { url: thumbnailUrl ?? "", description: `${itemName} - ${brand}` },
		priority: isFirstCartElement,
		slot: "140px",
	});
	const desktopProps = {
		...mobileProps,
		slot: "176px",
	};

	return (
		<a
			className={`${styles.wrapper} ${styles[className]}`}
			href={linkToProduct}
			onClick={handleClick}
			data-testid="ProductThumbnail_link"
		>
			<CustomImage
				mobile={mobileProps}
				desktop={desktopProps}
				isFromContentful={false}
				hasMixBlend
			/>
		</a>
	);
};

export default ProductThumbnail;
