import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";
import {
	documentToReactComponents,
	type RenderMark,
	type RenderNode,
	type RenderText,
} from "@contentful/rich-text-react-renderer";
import type { Document } from "@contentful/rich-text-types";
import { isEmpty } from "lodash";

import { getTargetBlankLinkOptions, renderTextOptions } from "./contentful.utils";

interface IRenderRichTextWithLinks {
	richTextDocument?: Document;
	additionalRenderNodeOptions?: RenderNode;
	additionalRenderMarkOptions?: RenderMark;
	openLinksInANewTab?: boolean | null;
	additionalRenderTextOptions?: RenderText;
}

export const renderRichTextWithLinks = ({
	richTextDocument,
	additionalRenderNodeOptions = {},
	additionalRenderMarkOptions = {},
	additionalRenderTextOptions,
	openLinksInANewTab = false,
}: IRenderRichTextWithLinks) => {
	if (!richTextDocument || isEmpty(richTextDocument)) return null;

	const options = openLinksInANewTab
		? getTargetBlankLinkOptions(
				additionalRenderNodeOptions,
				additionalRenderMarkOptions,
				additionalRenderTextOptions
		  )
		: {
				renderNode: additionalRenderNodeOptions,
				renderMark: additionalRenderMarkOptions,
				renderText: (text: string) => renderTextOptions(text, additionalRenderTextOptions),
		  };

	return documentToReactComponents(richTextDocument, options);
};

export const renderTextWithRichText = (richTextDocument: Document) => {
	if (isEmpty(richTextDocument)) return null;

	return documentToPlainTextString(richTextDocument);
};
