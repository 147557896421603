import { IProps } from "./LinkArrow.types";
import LinkLabel from "./LinkLabel.component";
import styles from "./LinkArrow.module.scss";

const LinkHref = ({ url, label, size, target, rel }: IProps) => {
	return (
		<a
			href={url}
			target={target}
			rel={rel}
			className={styles.linkWrapper}
			data-testid="LinkHref_cta-link"
		>
			<LinkLabel label={label} size={size} />
		</a>
	);
};

export default LinkHref;
