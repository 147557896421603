import Icon, { EIcon } from "@components/Icon/Icon.component";
import { useChangeLocale } from "@hooks/useLocale";
import { IPropsComponent } from "../type";
import LangSwitcher from "@components/LangSwitcher/LangSwitcher.component";

const icons: Record<string, EIcon> = {
	en: EIcon.English,
	fr: EIcon.French,
};

const Lang = (props: IPropsComponent) => {
	const changeLang = useChangeLocale();

	return (
		<div {...props}>
			<LangSwitcher changeLang={changeLang}>
				<Icon icon={icons[changeLang]} />
			</LangSwitcher>
		</div>
	);
};

export default Lang;
