import React, { forwardRef, ForwardRefRenderFunction, useEffect } from "react";

import MenuSection from "./MenuSection/MenuSection.component";
import { TMenuLevel, ILevelProps, EMenuFirstQueryIdentifier } from "./menu.types";

import { useResponsiveOnLoad } from "@hooks/useDevice";

import styles from "./Menu.module.scss";

const MenuLevel: ForwardRefRenderFunction<HTMLDivElement, ILevelProps<TMenuLevel[]>> = (
	{ content, label, hasLevel3, setIsMenuLoaded, ...rest }: ILevelProps<TMenuLevel[]>,
	ref
) => {
	const { isLoad } = useResponsiveOnLoad();

	useEffect(() => {
		if (isLoad) setIsMenuLoaded?.(true);
	}, [isLoad]);

	const extraClassName = hasLevel3 ? styles.hasLevel3 : "";

	return (
		<div className={styles.menuLevelWrapper} ref={ref}>
			{label && (
				<p
					className={`${styles.levelLabel} ${styles[rest.classNameLevel]}`}
					data-testid="MenuLevel_label"
				>
					{label}
				</p>
			)}
			<nav className={`${styles.levelWrapper} ${styles[rest.classNameLevel]} ${extraClassName}`}>
				{content?.map((item) => {
					if (item?.identifier === EMenuFirstQueryIdentifier.MenuContentSportsList) return null;
					return (
						<MenuSection
							key={item?.sys?.id}
							content={item}
							topHeaderContentLength={content[0].contentCollection.items.length}
							{...rest}
						/>
					);
				})}
			</nav>
		</div>
	);
};

export default forwardRef(MenuLevel);
