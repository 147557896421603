import React from "react";

import { ITextWithLinkCflData } from "@components/ContentfulComponents/ComponentTextWithLink/type";
import LinkArrow from "@components/LinkArrow/LinkArrow.component";

import { useAbsolute } from "@hooks/useAbsolute";
import { convertRelOptions, convertTargetOptions } from "@/shared/contentful/contentful.utils";
import { renderRichTextWithLinks } from "@shared/contentful/contentful-render.utils";
import { replaceAllBreakSpaces } from "@shared/text/text.utils";

import Icon, { EIcon } from "../Icon/Icon.component";

import styles from "./Promises.module.scss";
import { colors } from "@shared/styles/theme";

export const getPromiseIcon = (icon: string | undefined): EIcon | undefined => {
	switch (icon) {
		case "pick-up":
			return EIcon.PickUpStore;
		case "help":
			return EIcon.Question;
		case "returns":
			return EIcon.Return;
		case "shipping":
			return EIcon.FastDelivery;
	}
};

const PromiseLink = ({ link }: Pick<ITextWithLinkCflData, "link">) => {
	const { url, label, targetOptions, relOptions } = link || {};
	const target = convertTargetOptions(targetOptions);
	const rel = convertRelOptions(relOptions);
	const { absoluteUrl } = useAbsolute(url);

	return (
		<>
			{absoluteUrl && (
				<LinkArrow url={absoluteUrl} target={target} rel={rel} label={label} size="14" />
			)}
		</>
	);
};

const PromiseComponent = ({ icon, title, richTextContent, link }: ITextWithLinkCflData) => {
	const promiseIcon = getPromiseIcon(icon);
	const { primary } = colors;

	return (
		<div data-testid="Promise_wrapper">
			<div className={styles.promiseIcon}>
				<Icon icon={promiseIcon} size={40} noHover noActive color={primary} />
			</div>
			{title && (
				<p className={styles.title} data-testid="Promise_title">
					<strong>{replaceAllBreakSpaces(title)}</strong>
				</p>
			)}
			{richTextContent?.json && renderRichTextWithLinks({ richTextDocument: richTextContent.json })}
			{link && <PromiseLink link={link} />}
		</div>
	);
};

export default PromiseComponent;
