import React from "react";
import isEmpty from "lodash/isEmpty";
import { useResponsiveOnLoad } from "@hooks/useDevice";
import { EImageSlot, ICustomImageProps } from "@components/CustomImage/custom-images.types";
import { generateSrcSet, getImageSource } from "@components/CustomImage/custom-images.utils";

import styles from "@components/CustomImage/custom-image.module.scss";

const CustomContentImage = ({
	mobile,
	tablet,
	desktop,
	isFromContentful = true,
	hasMixBlend = false,
	className = "",
	loading,
	priority,
}: ICustomImageProps) => {
	const {
		isLoad,
		devices: { isTablet, isDesktop, isBigScreen },
	} = useResponsiveOnLoad();

	const imageSource = getImageSource({
		mobile,
		tablet,
		desktop,
		isTablet,
		isDesktop,
		isBigScreen,
	});

	if (!isLoad || !imageSource || isEmpty(imageSource)) {
		return null;
	}

	return (
		<div
			className={`${styles.imgWrapper} ${hasMixBlend ? styles.mixBlend : ""} ${className}`}
			data-testid="CustomContentImage_wrapper"
		>
			<picture>
				<source
					srcSet={generateSrcSet({
						src: imageSource.url,
						isFromContentful,
					})}
					type="image/webp"
					data-testid="CustomContentImage_source-webp"
				/>
				<source
					srcSet={generateSrcSet({
						src: imageSource.url,
						isFromContentful,
						isWebp: false,
					})}
					type="image/jpg"
					data-testid="CustomContentImage_source-jpg"
				/>
				<img
					src={imageSource.url}
					alt={imageSource.alt}
					sizes={imageSource.slot || EImageSlot.Full}
					width={imageSource.initialWidth}
					height={imageSource.initialHeight}
					decoding="async"
					data-testid="CustomContentImage_img-fallback"
					className={styles.contentImage}
					loading={loading ? "lazy" : undefined}
					// eslint-disable-next-line react/no-unknown-property
					fetchpriority={priority ? "high" : undefined}
				/>
			</picture>
		</div>
	);
};

export default CustomContentImage;
