import { Document } from "@contentful/rich-text-types";
import { EComponentTypenames, ISysCfl } from "@shared/contentful/contentful.types";

export enum ERichTextCflDisplayOptions {
	InfoMessage = "info message",
	Headline = "headline",
	Regular = "regular rich text",
}

export interface IRichTextCflData extends ISysCfl {
	__typename: EComponentTypenames;
	identifier?: string;
	displayAs?: ERichTextCflDisplayOptions;
	openLinksInANewTab?: boolean | null;
	richTextContent?: IJsonDocument;
}

export interface IJsonDocument {
	json: Document;
}
