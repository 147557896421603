import { useState, FocusEvent, useRef, useEffect } from "react";
import Input from "@components/Input/Input.component";
import {
	formatPostalCode,
	handleInputChange,
	handleKeyDown,
	validateInputOnBlur,
} from "@utils/postalCode.utils";
import useGraphQL from "@hooks/useGraphql";
import { getContentTranslations } from "@shared/contentful/translations.utils";
import { EPostalCodeIdentifiers, postalCodeInputQuery } from "./PostalCode.query";
import { IContentBlockCollection } from "@shared/contentful/contentful.types";
import { TContentTranslation } from "@shared/types/translations.types";
import { ITranslationKeyCflData } from "@components/ContentfulComponents/ComponentTranslationKey/type";
import { IPostalCode } from "@shared/checkout/delivery.types";
import { handleSelectionRange } from "@utils/selectionRange.utils";
import { useDispatch } from "react-redux";
import { setCartErrors } from "@redux/cart/cart-content.reducer";

type TPostalCodeInputData = IContentBlockCollection<ITranslationKeyCflData>;

interface IPostalCodeVariables {
	identifier: EPostalCodeIdentifiers.TextPostalCodeInput;
}

const PostalCode = ({
	label,
	placeholder,
	className,
	onBlur,
	value,
	errorMessage,
	setPostalCodeValue,
	postalCodeValidator,
	setErrorMessageApi,
	cartErrors,
}: IPostalCode) => {
	const [error, setError] = useState<string | undefined>();

	const { data } = useGraphQL<TPostalCodeInputData, IPostalCodeVariables>(postalCodeInputQuery, {
		variables: {
			identifier: EPostalCodeIdentifiers.TextPostalCodeInput,
		},
	});
	const inputRef = useRef<HTMLInputElement>(null);
	const [selectionStart, setSelectionStart] = useState<number | null>(null);
	const [isRemoveKeyPressed, setIsRemoveKeyPressed] = useState<boolean>(false);
	const [changedValue, setChangedValue] = useState(value);
	const dispatch = useDispatch();

	useEffect(() => {
		handleSelectionRange(selectionStart, isRemoveKeyPressed, inputRef, [4]);
		return () => {
			setIsRemoveKeyPressed(false);
		};
	}, [changedValue]);

	if (!data) return null;

	const translations: TContentTranslation<string> = getContentTranslations(
		data.contentBlockCollection?.items[0]?.contentCollection.items
	);

	const defaultLabel = translations[EPostalCodeIdentifiers.TextPostalCodeInputLabel];
	const defaultPlaceholder =
		translations[EPostalCodeIdentifiers.TextPostalCodeInputPlaceholder] || label;
	const defaultHandleInputBlur = (e: FocusEvent<HTMLInputElement>) => {
		const { value: inputValue } = e.target;
		validateInputOnBlur({
			value: inputValue,
			translation: translations[EPostalCodeIdentifiers.TextPostalCodeErrorMessageInvalid],
			setError,
		});
	};

	return (
		<Input
			className={className}
			label={label || defaultLabel}
			placeholder={placeholder || defaultPlaceholder}
			type="text"
			onChange={(e) => {
				if (cartErrors && cartErrors.length > 0) {
					dispatch(setCartErrors([]));
				}
				handleInputChange({
					target: e.target,
					setSelectionStart,
					setPostalCodeValue,
					setErrorMessageApi,
					postalCodeValidator,
					setChangedValue,
				});
			}}
			onKeyDown={({ key }) => handleKeyDown({ key, setIsRemoveKeyPressed })}
			onFocus={() => setError(undefined)}
			onBlur={onBlur ?? defaultHandleInputBlur}
			value={formatPostalCode(value as string)}
			errorMessage={errorMessage || error}
			data-testid="PostalCode_input"
			maxLength={7}
			ref={inputRef}
		/>
	);
};

export default PostalCode;
