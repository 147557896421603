import { gql } from "graphql-request";
import { componentTranslationKeyFragment } from "@components/ContentfulComponents/ComponentTranslationKey/fragment";

export enum EPostalCodeIdentifiers {
	TextPostalCodeInput = "text_postal-code-input",
	TextPostalCodeInputLabel = "text_postal-code-input-label",
	TextPostalCodeInputPlaceholder = "text_postal-code-input-placeholder",
	TextPostalCodeErrorMessageInvalid = "text_postal-code-error-message-invalid",
}

export const postalCodeInputQuery = gql`
	${componentTranslationKeyFragment}
	query postalCodeInput($identifier: String, $locale: String) {
		contentBlockCollection(where: { identifier: $identifier }, locale: $locale, limit: 1) {
			items {
				identifier
				contentCollection(limit: 3) {
					items {
						...translationKey
					}
				}
			}
		}
	}
`;
