import { convertRelOptions } from "@shared/contentful/contentful.utils";
import { useRouter } from "next/router";
import useLocale from "./useLocale";

export const useIsHomepage = () => {
	const { locale } = useLocale();
	const router = useRouter();
	return router.asPath === "/" || router.asPath === `/${locale}`;
};

export const useRelOptionsFollowHome = (relOptions: string[] | undefined) => {
	const isHomepage = useIsHomepage();

	if (!relOptions || relOptions.length === 0) {
		return undefined;
	}
	if (isHomepage && relOptions.includes("nofollow")) {
		const index = relOptions.findIndex((option) => option === "nofollow");
		relOptions[index] = "follow";
	}
	return convertRelOptions(relOptions);
};
