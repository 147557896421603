import { getPropsForImages } from "@components/CustomImage/custom-images.utils";
import CustomContentImage from "@components/CustomImage/CustomContentImage/CustomContentImage.component";
import Video from "@components/ContentfulComponents/ComponentAsset/Video/Video.component";

import { IMediaCflData } from "@components/ContentfulComponents/ComponentAsset/type";
import { EImageSlot } from "@components/CustomImage/custom-images.types";
import { EVideoOptions } from "@components/ContentfulComponents/ComponentAsset/utils/asset.utils";

import styles from "@components/ContentfulComponents/ComponentAsset/asset.module.scss";

interface IMedia {
	asset: IMediaCflData;
	assetDesktop?: IMediaCflData | null;
	videoOptions?: EVideoOptions[] | null;
	imageSlot?: { mobile: EImageSlot | string; desktop: EImageSlot | string };
	className?: string;
	loading?: boolean;
	priority?: boolean;
}

const Media = ({
	asset,
	assetDesktop,
	videoOptions,
	imageSlot,
	className,
	loading,
	priority,
}: IMedia) => {
	if (!asset) return null;

	const mobileProps = getPropsForImages({
		image: asset,
		slot: imageSlot?.mobile,
	});
	const desktopProps = getPropsForImages({
		image: assetDesktop ?? asset,
		slot: imageSlot?.desktop,
	});

	return asset.url?.startsWith("https://videos") ? (
		<Video
			assetUrl={asset.url}
			videoOptions={videoOptions}
			className={`${styles.videoWrapper} ${className}`}
		/>
	) : (
		<CustomContentImage
			mobile={mobileProps}
			desktop={desktopProps}
			className={className}
			loading={loading}
			priority={priority}
		/>
	);
};

export default Media;
