import React, { useEffect, useState } from "react";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";

import {
	TAccountSubmenuData,
	IAccountSubmenuVariables,
	EAccountSubmenuIdentifiers,
} from "./Account.types";
import { accountSubmenuQuery } from "./Account.query";

import Panel from "@components/Panel/Panel.component";
import Icon, { EIcon } from "@components/Icon/Icon.component";
import { IPropsComponent } from "@components/Header/Components/type";
import Login from "@components/Login/Login.component";
import Logout from "@components/Logout/Logout.component";
import LineDivider from "@components/LineDivider/LineDivider.component";
import SimpleLink from "@components/MenuLinkUnderlined/SimpleLink.component";
import { ILinkCflData } from "@components/ContentfulComponents/ComponentLink/type";
import { ELayoutIdentifiers } from "@components/Layout/type";

import useToggle from "@hooks/useToggle";
import useGraphQL from "@hooks/useGraphql";
import useHandleClosePortal from "@hooks/useHandleClosePortal.hook";

import { useSelector } from "react-redux";
import { selectTranslationStore } from "@redux/translation/translation.selector";

import { getContentTranslations } from "@shared/contentful/translations.utils";
import { isLink } from "@shared/contentful/contentful.utils";
import { TGenericContent } from "@shared/contentful/contentful.types";

import { getMenuLinkTestId } from "@utils/account.utils";

import styles from "./Account.module.scss";

const Account = (props: IPropsComponent) => {
	const containerId = "account-item";
	const translations = useSelector(selectTranslationStore);
	const { data: session } = useSession();

	const [displayLoginPanel, toggleLoginPanel] = useToggle(false);
	const [lastActiveElement, setLastActiveElement] = useState<HTMLElement | null>(null);
	const parentDiv = useHandleClosePortal<HTMLDivElement>(
		displayLoginPanel ? toggleLoginPanel : () => null
	);

	const { asPath } = useRouter();

	const pathsToRedirectToHome = asPath === "/cart" || asPath.includes("my-account");

	const overridenLogoutRedirectUrl = pathsToRedirectToHome ? "/" : asPath;

	useEffect(() => {
		if (displayLoginPanel) {
			// capture last focused element
			setLastActiveElement(document.activeElement as HTMLElement);
			parentDiv?.current?.focus();
		} else {
			// focus on the element focused before opening the modal
			lastActiveElement?.focus();
		}
	}, [displayLoginPanel]);

	const { data: accountSubmenu } = useGraphQL<TAccountSubmenuData, IAccountSubmenuVariables>(
		accountSubmenuQuery,
		{
			variables: { identifier: EAccountSubmenuIdentifiers.BlockAccountSubmenu },
		}
	);

	if (!accountSubmenu) return null;

	const dataTranslations = getContentTranslations<string | ILinkCflData>(
		accountSubmenu.contentBlockCollection?.items[0]?.contentCollection.items
	);

	const ariaIconAccount = translations[ELayoutIdentifiers.AriaIconAccount] || "my account";
	const textAccountSubmenuHello = (dataTranslations[
		EAccountSubmenuIdentifiers.TranslationAccountSubmenuHello
	] || "") as string;
	const textAccountSubmenuLogout = (dataTranslations[
		EAccountSubmenuIdentifiers.TranslationAccountSubmenuLogout
	] || "") as string;

	if (!session) {
		return (
			<Login isHeader {...props}>
				<Icon icon={EIcon.User} aria-label={ariaIconAccount} />
			</Login>
		);
	}

	return (
		<div id={containerId} ref={parentDiv}>
			<button
				onClick={() => toggleLoginPanel()}
				data-testid="Account_user-button"
				aria-label={ariaIconAccount}
				{...props}
			>
				<Icon icon={EIcon.UserLoggedIn} aria-label={ariaIconAccount} />
			</button>
			{displayLoginPanel && (
				<Panel rootId={containerId} classNameWrapper={styles.loginPanel}>
					<div className={styles.firstItemGroup}>
						<p className={styles.helloUser} data-testid="Account_user-id-text">
							{textAccountSubmenuHello} {session?.user?.given_name}
						</p>
					</div>
					<div className={styles.itemsGroupSeparator}>
						<LineDivider />
					</div>
					<div className={styles.itemsGroup} data-testid="Account_menu">
						{Object.entries(dataTranslations).map(([key, value]) => {
							if (!isLink(value as TGenericContent<string | ILinkCflData>)) return null;
							return (
								<SimpleLink
									key={key}
									link={value as ILinkCflData}
									testid={getMenuLinkTestId(key)}
									className={styles.menuLink}
								/>
							);
						})}
					</div>
					<div className={styles.itemsGroupSeparator}>
						<LineDivider />
					</div>
					<div className={styles.lastItemGroup}>
						<Logout className={styles.menuLink} overridenRedirectUrl={overridenLogoutRedirectUrl}>
							{textAccountSubmenuLogout}
						</Logout>
					</div>
				</Panel>
			)}
		</div>
	);
};

export default Account;
