import { Session } from "next-auth";

export const buildDataLayerLoggedUser = (session: Session) => {
	if (!session?.user) {
		return;
	}
	const { member, person } = session.user;
	return {
		user: {
			shared_id_member: member?.memberIdEncrypted,
			shared_id_person: person?.personIdEncrypted,
			person_id: person?.personId,
			member_id: member?.memberId,
		},
	};
};
