import { EMyAccountMenuIdentifiers } from "@components/MyAccount/MyAccountLayout/MyAccountLayout.types";

export const getMenuLinkTestId = (testId: string) => {
	switch (testId) {
		case EMyAccountMenuIdentifiers.LinkMyAccountMenuDashboard:
			return "Account_dashboard-link";
		case EMyAccountMenuIdentifiers.LinkMyAccountMenuProfile:
			return "Account_profile-link";
		case EMyAccountMenuIdentifiers.LinkMyAccountMenuAddress:
			return "Account_address-link";
		case EMyAccountMenuIdentifiers.LinkMyAccountMenuPurchaseHistory:
			return "Account_purchase-history-link";
		case EMyAccountMenuIdentifiers.LinkMyAccountMenuCommunications:
			return "Account_communications-link";

		default:
			return testId;
	}
};
