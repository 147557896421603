import React, { ReactNode, useContext } from "react";
import { ELayoutIdentifiers } from "@components/Layout/type";
import { TranslationContext } from "@components/Layout/Layout.component";
import { useAbsolute } from "@hooks/useAbsolute";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import { selectTranslationStore } from "@redux/translation/translation.selector";

interface ILangSwitcherProps {
	changeLang: string;
	children: ReactNode;
}

const LangSwitcher = ({ changeLang, children }: ILangSwitcherProps) => {
	const { translatedPath } = useContext(TranslationContext);

	const { asPath } = useRouter();

	const { absoluteUrl } = useAbsolute(translatedPath || asPath, changeLang);
	const translations = useSelector(selectTranslationStore);

	if (!absoluteUrl) {
		return <></>;
	}

	return (
		<a
			href={absoluteUrl}
			data-testid="LangSwitcher_btn"
			aria-label={translations[ELayoutIdentifiers.AriaLangSwitcher]}
		>
			{children}
		</a>
	);
};

export default LangSwitcher;
