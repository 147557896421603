import { useEffect, useRef, useState } from "react";

import { ISteps } from "./Menu.component";
import { IDisplayMenu, TMenuLevel } from "./menu.types";
import MenuFooter from "./MenuFooter/MenuFooter.component";
import MenuLevel from "./MenuLevel.component";
import { isMenuFullyDisplayed } from "./MenuView.utils";

import { ITranslationKeyCflData } from "@components/ContentfulComponents/ComponentTranslationKey/type";
import { ELayoutIdentifiers } from "@components/Layout/type";
import Icon, { EIcon } from "@components/Icon/Icon.component";
import PanelSlide, { EDirection } from "@components/PanelSlide/PanelSlide.component";

import { TContentTranslation } from "@shared/types/translations.types";

import styles from "./Menu.module.scss";

interface IMenuView {
	panelWidth: string;
	displayMenu: IDisplayMenu;
	closeAndReset: () => void;
	levelPanelClassName: string;
	level: number;
	stepBack: () => void;
	translations: TContentTranslation<string>;
	levelSteps: ISteps;
	updateContent: (newContent: TMenuLevel[], newLabel: string | undefined) => void;
	lastLabel: React.MutableRefObject<string | undefined>;
	isLarge: boolean;
	footerData: ITranslationKeyCflData[];
	lastPanelRef: React.RefObject<HTMLDivElement>;
	animationStatus?: string;
}

const MenuView = ({
	panelWidth,
	displayMenu,
	closeAndReset,
	levelPanelClassName,
	level,
	stepBack,
	translations,
	levelSteps,
	updateContent,
	lastLabel,
	isLarge,
	footerData,
	lastPanelRef,
	animationStatus,
}: IMenuView) => {
	const [animationStatusClassName, setAnimationStatusClassName] = useState<string | undefined>();
	const [showShadow, setShowShadow] = useState(true);
	const [isMenuLoaded, setIsMenuLoaded] = useState(false);
	const [isAlreadyCalculated, setIsAlreadyCalculated] = useState(false);
	const [isFullyDisplayed, setIsFullyDisplayed] = useState(false);
	const panelRef = useRef<HTMLDivElement>(null);
	const headerRef = useRef<HTMLDivElement>(null);
	const menuRef = useRef<HTMLDivElement>(null);
	const footerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		switch (animationStatus) {
			case "stepBackAnim":
				return setAnimationStatusClassName(styles.stepBackAnim);
			case "lastPanelAnim":
				return setAnimationStatusClassName(styles.lastPanelAnim);
			default:
				return setAnimationStatusClassName(undefined);
		}
	}, [animationStatus]);

	useEffect(() => {
		if (displayMenu.isDisplay && isMenuLoaded && !isAlreadyCalculated) {
			const isFullyDisplayed = isMenuFullyDisplayed({ panelRef, headerRef, menuRef, footerRef });
			setShowShadow(!isFullyDisplayed);
			setIsAlreadyCalculated(true);
			setIsFullyDisplayed(isFullyDisplayed);
		}
	}, [displayMenu.isDisplay, isMenuLoaded]);

	const ariaIconMenuBack =
		translations[ELayoutIdentifiers.AriaIconMenuBack] || "return to previous menu level";
	const ariaIconMenuClose = translations[ELayoutIdentifiers.AriaIconMenuClose] || "close menu";

	return (
		<PanelSlide
			width={panelWidth}
			position={EDirection.Left}
			isOpen={displayMenu.isDisplay}
			toggleOpen={closeAndReset}
			className={`${styles.menuPanelSlide} ${animationStatusClassName}`}
			panelName={"navigation"}
			setShowShadow={setShowShadow}
			isFullyDisplayed={isFullyDisplayed}
		>
			<div className={`${styles.menuGrid} ${levelSteps[3] ? styles.twoColumns : ""}`}>
				<div className={`${styles.levelPanel} ${styles[levelPanelClassName]}`} ref={panelRef}>
					<div className={styles.iconsWrapper} ref={headerRef}>
						{level > 1 && (
							<button
								onClick={stepBack}
								aria-label={ariaIconMenuBack}
								data-testid="MenuView_step-back-btn"
							>
								<Icon icon={EIcon.ArrowLeft} aria-label={ariaIconMenuBack} />
							</button>
						)}
						<button
							onClick={closeAndReset}
							aria-label={ariaIconMenuClose}
							data-testid="MenuView_close-btn"
						>
							<Icon icon={EIcon.Close} aria-label={ariaIconMenuClose} />
						</button>
					</div>
					<MenuLevel
						currentLevel={level}
						content={levelSteps[level].content}
						label={levelSteps[level].label}
						updateContent={updateContent}
						lastLabel={lastLabel.current}
						closeAndReset={closeAndReset}
						classNameLevel={levelPanelClassName}
						hasLevel3={!!levelSteps[3]}
						setIsMenuLoaded={setIsMenuLoaded}
						ref={menuRef}
					/>
				</div>
				{level === 1 && !isLarge && (
					<MenuFooter footerData={footerData} showShadow={showShadow} ref={footerRef} />
				)}
			</div>
			{levelSteps[3] && isLarge && (
				<div
					className={`${styles.levelPanel} ${styles.lastPanel}`}
					ref={lastPanelRef}
					data-testid="MenuView_last-level"
				>
					<MenuLevel
						currentLevel={level}
						content={levelSteps?.[3].content}
						label={levelSteps?.[3].label}
						updateContent={updateContent}
						closeAndReset={closeAndReset}
						classNameLevel={`level-3`}
					/>
				</div>
			)}
		</PanelSlide>
	);
};

export default MenuView;
