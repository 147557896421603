import React from "react";

import { menuSecondQuery } from "@components/Header/Default/menu.query";
import Icon, { EIcon } from "@components/Icon/Icon.component";
import { EMenuFirstQueryIdentifier, ILevelProps, ILevelTwoGQL } from "@components/Menu/menu.types";
import { fakeSportsList } from "@components/Menu/sports-list.data";
import useGraphQL from "@hooks/useGraphql";
import { colors } from "@shared/styles/theme";

import styles from "./MenuLevelLink.module.scss";
import { IMenuHeaderCflData } from "@components/ContentfulComponents/BlockMegaMenu/type";
import { replaceAllBreakSpaces } from "@shared/text/text.utils";

const { greyLight2 } = colors;

const MenuLevelLink = ({
	content,
	updateContent,
	currentLevel,
	lastLabel,
	classNameLevel = "",
	style,
}: ILevelProps<IMenuHeaderCflData>) => {
	const {
		label,
		isHighlighted,
		sys: { id },
	} = content;

	const { data: levelTwoData } = useGraphQL<ILevelTwoGQL>(menuSecondQuery, {
		variables: { id },
	});

	const updateLevelContent = () => {
		// TODO: this part is broken for the moment, but I'll do
		// the work in another PR
		if (id === EMenuFirstQueryIdentifier.MenuContentSportsList) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			return updateContent(fakeSportsList as any, label);
		}
		if (levelTwoData) {
			const data = levelTwoData.grpMenuSection?.contentCollection?.items;
			return data && updateContent(data, label);
		}
	};

	const isActive = label === lastLabel;

	return (
		<li
			className={`${styles.isLevelLink} ${isActive ? styles.isActive : ""} ${
				styles[classNameLevel]
			}`}
			data-testid="MenuItem_level-link"
		>
			<button
				onClick={updateLevelContent}
				data-testid={"MenuItem_update-level-button"}
				className={`
					${styles.btnUpdateLevel}
					${styles.fadeIn}
					${isHighlighted ? styles.isHighlighted : ""}
				`}
				style={style}
			>
				<span>
					<span className={styles.updateLevelLabel}>{replaceAllBreakSpaces(label)}</span>
					{currentLevel && currentLevel > 1 && (
						<Icon icon={EIcon.ChevronRight} color={greyLight2} noActive noHover />
					)}
				</span>
			</button>
		</li>
	);
};
export default MenuLevelLink;
