import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useResponsive } from "@hooks/useDevice";
import { IDisplayMenu, TMenuLevel } from "./menu.types";
import { IMenuButtonProps } from "@components/Header/Components/type";
import { useSelector } from "react-redux";
import { selectTranslationStore } from "@redux/translation/translation.selector";
import MenuView from "./MenuView.component";

interface IProps {
	content: IMenuButtonProps;
	displayMenu: IDisplayMenu;
	toggleMenu: Dispatch<SetStateAction<IDisplayMenu>>;
}

export interface ISteps {
	[key: number]: { content: TMenuLevel[]; label?: string };
}

const WIDTH_DEFAULT = "100%";
const WIDTH_DESKTOP = "488px";
const WIDTH_DESKTOP_LARGE = "863px";

const Menu = ({ toggleMenu, displayMenu, content: { menuData, footerData } }: IProps) => {
	const [level, setLevel] = useState(1);
	const lastLabel = useRef<string | undefined>();
	const [levelSteps, setLevelSteps] = useState<ISteps>({ [level]: { content: menuData } });
	const [panelWidth, setPanelWidth] = useState(WIDTH_DEFAULT);

	const { isDesktop, isBigScreen } = useResponsive();
	const isLarge = isDesktop || isBigScreen;
	const lastPanelRef = useRef<HTMLDivElement>(null);
	const animationRef = useRef<string | undefined>(undefined);

	const translations = useSelector(selectTranslationStore);

	useEffect(() => {
		setPanelWidth(isLarge ? WIDTH_DESKTOP : WIDTH_DEFAULT);
	}, [isLarge]);

	useEffect(() => {
		if (displayMenu.content) {
			const { content } = displayMenu;
			updateContent(content.data, content.label);
		}
	}, [displayMenu]);

	const initMenu = () => {
		setLevel(1);
		setLevelSteps({ [1]: { content: menuData } });
		setPanelWidth(isLarge ? WIDTH_DESKTOP : WIDTH_DEFAULT);
	};

	const closeAndReset = () => {
		animationRef.current = undefined;
		toggleMenu({ isDisplay: false });
		lastLabel.current = undefined;
		initMenu();
	};

	const handleDesktopLastLevel = (newLabel: string | undefined) => {
		animationRef.current = "lastPanelAnim";
		lastLabel.current = newLabel;
		setPanelWidth(WIDTH_DESKTOP_LARGE);
		lastPanelRef?.current?.focus();
	};

	const updateContent = (newContent: TMenuLevel[], newLabel: string | undefined) => {
		setLevelSteps({
			...levelSteps,
			[level + 1]: { content: newContent, label: newLabel },
		});

		if (level + 1 === 3 && isLarge) {
			return handleDesktopLastLevel(newLabel);
		}
		level + 1 <= 3 && setLevel(level + 1);
		lastLabel.current = undefined;
	};

	const stepBack = () => {
		if (isLarge) {
			animationRef.current = "stepBackAnim";
			initMenu();
		} else {
			setLevel(level - 1);
		}
	};

	const levelPanelClassName = `level-${level}`;

	return (
		<MenuView
			panelWidth={panelWidth}
			displayMenu={displayMenu}
			closeAndReset={closeAndReset}
			levelPanelClassName={levelPanelClassName}
			level={level}
			stepBack={stepBack}
			translations={translations}
			levelSteps={levelSteps}
			updateContent={updateContent}
			lastLabel={lastLabel}
			isLarge={isLarge}
			footerData={footerData}
			lastPanelRef={lastPanelRef}
			animationStatus={animationRef.current}
		/>
	);
};

export default Menu;
