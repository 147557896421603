const Loader = ({ color = "#fff", className = "" }) => (
	<div className={className} data-testid="Loader_wrapper">
		<svg
			version="1.1"
			id="L4"
			xmlns="https://www.w3.org/2000/svg"
			xmlnsXlink="https://www.w3.org/1999/xlink"
			x="0px"
			y="0px"
			width="60"
			height="24"
			viewBox="10 35 30 30"
			enableBackground="new 0 0 0 0"
			xmlSpace="preserve"
		>
			<circle fill={color} stroke="none" cx="6" cy="50" r="6">
				<animate
					attributeName="opacity"
					dur="1s"
					values="0;1;0"
					repeatCount="indefinite"
					begin="0.1"
				/>
			</circle>
			<circle fill={color} stroke="none" cx="26" cy="50" r="6">
				<animate
					attributeName="opacity"
					dur="1s"
					values="0;1;0"
					repeatCount="indefinite"
					begin="0.2"
				/>
			</circle>
			<circle fill={color} stroke="none" cx="46" cy="50" r="6">
				<animate
					attributeName="opacity"
					dur="1s"
					values="0;1;0"
					repeatCount="indefinite"
					begin="0.3"
				/>
			</circle>
		</svg>
	</div>
);

export default Loader;
