import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import useLocale from "@hooks/useLocale";
import styles from "./Search.module.scss";
import Autocomplete from "@components/Algolia/Autocomplete/Autocomplete.component";
import { connectSearchBox, InstantSearch } from "react-instantsearch-dom";
import algoliasearch from "algoliasearch";
import getConfig from "next/config";
import "@algolia/autocomplete-theme-classic/dist/theme.css";
import Icon, { EIcon } from "@components/Icon/Icon.component";
import { ELayoutIdentifiers } from "@components/Layout/type";
import { useSelector } from "react-redux";
import { selectTranslationStore } from "@redux/translation/translation.selector";
import { ISearchState } from "@shared/algolia/algolia.types";
import { useResponsive } from "@hooks/useDevice";
import { LayoutContext } from "@components/Layout/Layout.context";
import { initAlgolia, validateAlgoliaConsent } from "./Search.utils";
import { selectMiscCookieConsent } from "@redux/misc/misc.selector";

const VirtualSearchBox = connectSearchBox(() => null);

const {
	publicRuntimeConfig: { algoliaAppId, algoliaApiKey, algoliaIndexName },
} = getConfig();

const searchClient = algoliasearch(algoliaAppId, algoliaApiKey);

const Search = () => {
	const { locale } = useLocale();
	const [searchState, setSearchState] = useState<ISearchState>({});
	const translations = useSelector(selectTranslationStore);
	const overlayRef = useRef<HTMLInputElement>(null);
	const { isDesktop, isBigScreen } = useResponsive();
	const isScreenDesktop = isBigScreen || isDesktop;
	const { searchBarFocused, setSearchBarFocused } = useContext(LayoutContext);
	const isCookieConsentGranted = useSelector(selectMiscCookieConsent);

	useEffect(() => {
		const handlerClickOutside = (event: Event) => {
			if (overlayRef.current?.contains(event.target as Node)) {
				setSearchBarFocused?.(false);
			}
		};
		window.addEventListener("click", handlerClickOutside);
		return () => window.removeEventListener("click", handlerClickOutside);
	}, []);

	useEffect(() => {
		isCookieConsentGranted ? validateAlgoliaConsent() : initAlgolia();
	}, [isCookieConsentGranted]);

	const ariaIconClose =
		translations[ELayoutIdentifiers.AriaIconCloseSearchPanel] || "close search panel";

	const onReset = useCallback(() => {
		setSearchBarFocused?.(false);
	}, []);

	const onFocus = useCallback(() => {
		setSearchBarFocused?.(true);
	}, []);

	return (
		<>
			{searchBarFocused && isScreenDesktop && (
				<div
					data-testid="Search_overlay"
					role="button"
					className={styles.overlay}
					tabIndex={0}
					aria-label={ariaIconClose}
					ref={overlayRef}
				/>
			)}
			<div className={styles.containerSearch}>
				<div className={`${styles.containerFormSearch}`}>
					<InstantSearch
						searchClient={searchClient}
						indexName={`${algoliaIndexName}_${locale}`}
						searchState={searchState}
						onSearchStateChange={setSearchState}
					>
						<VirtualSearchBox />
						<Autocomplete
							placeholder={translations[ELayoutIdentifiers.TextInputPlaceholderSearch]}
						/>
					</InstantSearch>
				</div>
				{searchBarFocused && (
					<button
						className={styles.containerClosePanel}
						onClick={onReset}
						onFocus={onFocus}
						onBlur={onReset}
						aria-label={ariaIconClose}
						data-testid="Search_button-close"
					>
						<Icon icon={EIcon.Close} aria-label={ariaIconClose} className={styles.iconClose} />
					</button>
				)}
			</div>
		</>
	);
};

export default Search;
