import useLocale from "@hooks/useLocale";
import { displayPriceWithCurrency, findPriceBeforeDiscount } from "@shared/product/product.utils";
import { IPriceProps } from "./ProductPrice.types";

import styles from "@components/ProductInfo/ProductInfo.module.scss";

const ClearancePrice = ({ price, className, labelPriceDrop, modelId }: IPriceProps) => {
	const { locale } = useLocale();
	const priceBeforeDiscount = findPriceBeforeDiscount({
		mappingId: labelPriceDrop?.mappingId,
		modelId,
	});
	return (
		<div className={`${styles.priceWrapper} ${className}`}>
			<p className={styles.regularPrice} data-testid="ClearancePrice_regular">
				{displayPriceWithCurrency(price.finalPrice, locale)}
			</p>
			{(!!price.slashedPrice || !!priceBeforeDiscount) && (
				<p className={styles.reducedPrice} data-testid="ClearancePrice_reduced">
					{displayPriceWithCurrency(priceBeforeDiscount ?? price.slashedPrice, locale)}
				</p>
			)}
		</div>
	);
};

export default ClearancePrice;
