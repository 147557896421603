/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { createAutocomplete } from "@algolia/autocomplete-core";
import algoliasearch from "algoliasearch";
import getConfig from "next/config";
import useLocale from "@hooks/useLocale";
import { useRouter } from "next/router";
import Icon, { EIcon } from "@components/Icon/Icon.component";
import { colors } from "@shared/styles/theme";
import { useSelector } from "react-redux";
import { selectTranslationStore } from "@redux/translation/translation.selector";
import { ELayoutIdentifiers } from "@components/Layout/type";
import styles from "./Autocomplete.module.scss";
import SearchQueryProducts from "@components/Header/Components/Search/SearchQueryProducts.component";
import SearchQuerySuggestions from "@components/Header/Components/Search/SearchQuerySuggestions.component";
import { useResponsive } from "@hooks/useDevice";
import { IAutocompleteState } from "@components/Header/Components/Search/types";
import { LayoutContext } from "@components/Layout/Layout.context";
import { getSourcesData } from "./Autocomplete.utils";

const {
	publicRuntimeConfig: { algoliaAppId, algoliaApiKey, algoliaIndexName },
} = getConfig();

const searchClient = algoliasearch(algoliaAppId, algoliaApiKey);

interface IAutocomplete {
	placeholder: string;
}

const Autocomplete = ({ placeholder }: IAutocomplete) => {
	const { locale } = useLocale();
	const [autocompleteState, setAutocompleteState] = useState<IAutocompleteState>({
		collections: [],
		isOpen: false,
		query: "",
	});
	const [showClearSearch, setShowClearSearch] = useState(false);
	const translations = useSelector(selectTranslationStore);
	const { searchBarFocused, setSearchBarFocused } = useContext(LayoutContext);
	const router = useRouter();
	const defaultValueRef = useRef<string>((router.query?.query as string) ?? "");
	const inputRef = useRef<HTMLInputElement>(null);
	const { isDesktop, isBigScreen } = useResponsive();
	const isScreenDesktop = isBigScreen || isDesktop;

	useEffect(() => {
		if (defaultValueRef.current) {
			!isScreenDesktop && setShowClearSearch(true);
			setAutocompleteState({ ...autocompleteState, query: defaultValueRef.current });
			if (inputRef.current) inputRef.current.value = defaultValueRef.current;
		}
	}, []);

	useEffect(() => {
		inputRef.current?.value?.length ? setShowClearSearch(true) : setShowClearSearch(false);
	}, [inputRef?.current?.value]);

	const handleClear = () => {
		setShowClearSearch(false);
		setAutocompleteState({ ...autocompleteState, query: "", isOpen: true });
		if (inputRef.current) {
			inputRef.current.value = "";
			inputRef.current.focus();
		}
	};

	const handleClearClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		handleClear();
	};

	const handleClearKeyUp = (e: React.KeyboardEvent<HTMLButtonElement>) => {
		if (e.key === "Enter") {
			handleClear();
		}
	};

	const handleClick = () => {
		if (inputRef.current) inputRef.current.focus();
	};

	const handleFocus = () => {
		setSearchBarFocused?.(true);
		setAutocompleteState({ ...autocompleteState, isOpen: true });
	};

	const handleKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
		if (e.key === "Enter" && !!inputRef?.current?.value.length) {
			e.preventDefault();
			const query = inputRef?.current?.value?.replace("&", "%26");
			window.location.href = `/${locale}/search?query=${query}`;
		} else if (e.key === "Escape") {
			setSearchBarFocused?.(false);
			setAutocompleteState({ ...autocompleteState, isOpen: false });
			if (inputRef.current) {
				inputRef.current.value = "";
				inputRef.current.blur();
			}
		}
	};

	const autocomplete = useMemo(
		() =>
			createAutocomplete({
				onStateChange({ state }) {
					setAutocompleteState(state as unknown as IAutocompleteState);
				},
				openOnFocus: true,
				getSources() {
					return [
						getSourcesData({
							sourceId: "querySuggestions",
							searchClient,
							indexName: `ecom_prod_query_suggestions_${locale}`,
							inputRef,
							hitsPerPage: 5,
						}),
						getSourcesData({
							sourceId: "queryProducts",
							searchClient,
							indexName: `${algoliaIndexName}_${locale}`,
							inputRef,
							hitsPerPage: 4,
						}),
					];
				},
			}),
		[autocompleteState]
	);

	const queryResults = autocompleteState.collections.filter(
		(collection) => collection.items.length > 0
	);

	const autocompleteDefaultAttributes = autocomplete.getInputProps({
		inputElement: inputRef.current,
	});

	const suggestionCount = autocompleteState.collections.flatMap(({ items }) => items).length;

	return (
		<div className={`aa-Automcomplete ${styles.autocompleteWrapper}`}>
			<form data-testid="Search_form" className="aa-Form">
				<Icon
					icon={EIcon.Search}
					noHover
					size={24}
					onClick={handleClick}
					data-testid="Autocomplete_icon"
				/>
				<input
					ref={inputRef}
					className="aa-Input"
					onFocus={(event) => {
						handleFocus();
						if (inputRef?.current?.value === "" || !autocompleteState.collections.length)
							autocompleteDefaultAttributes.onFocus(event as any);
					}}
					onKeyDown={handleKeyDown as any}
					id={autocompleteDefaultAttributes.id as any}
					onChange={autocompleteDefaultAttributes.onChange as any}
					placeholder={placeholder}
					aria-labelledby={autocompleteDefaultAttributes["aria-labelledby"]}
					aria-autocomplete={autocompleteDefaultAttributes["aria-autocomplete"]}
					autoComplete="off"
				/>
				{showClearSearch && (
					<button
						className={styles.clearSearchButton}
						onClick={handleClearClick}
						onKeyUp={handleClearKeyUp}
						aria-label={translations[ELayoutIdentifiers.AriaIconResetSearch]}
						data-testid="Search_clear-btn"
					>
						<Icon icon={EIcon.Delete} size={16} color={colors.greyLight3} noHover />
					</button>
				)}
			</form>

			{searchBarFocused && queryResults.length > 0 && autocompleteState.isOpen && (
				<div className="aa-Panel" data-testid="Autocomplete_panel">
					<SearchQuerySuggestions autocompleteState={autocompleteState} results={suggestionCount} />
					<SearchQueryProducts autocompleteState={autocompleteState} results={suggestionCount} />
				</div>
			)}
		</div>
	);
};

export default Autocomplete;
