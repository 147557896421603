import { componentLinkWithIconFragment } from "@components/ContentfulComponents/ComponentLink/fragment";
import { ILinkCflData } from "@components/ContentfulComponents/ComponentLink/type";
import { IContentBlockCollection } from "@shared/contentful/contentful.types";
import { gql } from "graphql-request";

export enum ESocialMediaIdentifier {
	BlockSocialMedia = "block_social-media",
}

export interface ISocialMediaVariables {
	identifier: ESocialMediaIdentifier;
}

export type TSocialMediaData = IContentBlockCollection<ILinkCflData>;

export const socialMediaQuery = gql`
	${componentLinkWithIconFragment}
	query socialMedia($identifier: String, $locale: String) {
		contentBlockCollection(where: { identifier: $identifier }, locale: $locale) {
			items {
				label
				contentCollection {
					items {
						...linkIcon
					}
				}
			}
		}
	}
`;
