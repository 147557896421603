import { Dispatch, ReactNode, SetStateAction } from "react";

import { IDescriptionData } from "@components/ProductDescription/productDescription.types";
import { IAlternate, IPriceData, IProduct } from "@shared/types/product.types";

export interface ILayoutVariables {
	identifier: ELayoutIdentifiers;
}

export interface IMetas
	extends Partial<Pick<IDescriptionData, "metaDescription" | "metaTitle">>,
		Pick<IAlternate, "fr" | "en"> {
	isMetaGeneral?: boolean;
	isMetaPriceImage?: boolean;
	metaType?: string;
}

export interface ILayoutProps
	extends Partial<Pick<IProduct, "brand" | "currentUrl" | "images">>,
		Partial<Pick<IPriceData, "finalPrice">>,
		Partial<Pick<IDescriptionData, "metaDescription" | "metaTitle">> {
	children: ReactNode;
	gtmBreadcrumbs?: string;
	pageType: string;
	robots?: string;
	metas?: IMetas;
	canonicalUrl?: string;
	openGraphTitle?: string;
	region?: string;
	additionalDataLayerInfo?: Record<string, unknown>;
	preview?: boolean;
	hideReinsurance?: boolean | null;
}

export enum ELayoutIdentifiers {
	BlockLayoutAriaLabels = "block_layout-aria-labels",
	AriaIconMenu = "aria_icon-menu",
	AriaIconMenuClose = "aria_icon-menu-close",
	AriaIconMenuBack = "aria_icon-menu-back",
	AriaLangSwitcher = "aria_lang-switcher",
	AriaIconAccount = "aria_icon-account",
	AriaAccountLogin = "aria_account-login",
	AriaIconCart = "aria_icon-cart",
	AriaIconStores = "aria_icon-stores",
	AriaIconResetSearch = "aria_icon-reset-search",
	TextInputPlaceholderSearch = "text_input-placeholder-search",
	AriaIconCloseModal = "aria_icon-close-modal",
	AriaIconCloseSearchPanel = "aria_icon-close-search-panel",
	TitleSearchQuerySuggestions = "title_search-query-suggestions",
	TitleSearchQueryProducts = "title_search-query-products",
}

export interface ILayoutContext {
	searchBarFocused: boolean;
	setSearchBarFocused?: Dispatch<SetStateAction<boolean>>;
}
