import { IContentBlockCflData } from "@components/ContentfulComponents/BlockContentBlock/type";
import { ITranslationKeyCflData } from "@components/ContentfulComponents/ComponentTranslationKey/type";
import Icon, { EIcon } from "@components/Icon/Icon.component";
import { colors } from "@shared/styles/theme";

import styles from "@components/Header/Components/PanelNotConnected/ConnectionAdvantages/ConnectionAdvantages.module.scss";

export interface IAdvantagesContentProps {
	data: IContentBlockCflData<ITranslationKeyCflData>;
}

const AdvantagesContent = ({ data }: IAdvantagesContentProps) => {
	if (!data?.contentCollection?.items?.length) return null;

	return (
		<ul className={styles.advantagesListWrapper}>
			{data.contentCollection.items.map(({ contentTranslation, sys }) => {
				return (
					<li key={sys?.id}>
						<Icon icon={EIcon.CheckCircle} noActive noHover color={colors.secondary} />
						{contentTranslation}
					</li>
				);
			})}
		</ul>
	);
};

export default AdvantagesContent;
