import { IContentBlockCflData } from "@components/ContentfulComponents/BlockContentBlock/type";
import { ILinkCflData } from "@components/ContentfulComponents/ComponentLink/type";
import { ITranslationKeyCflData } from "@components/ContentfulComponents/ComponentTranslationKey/type";
import { IContentBlockCollection } from "@shared/contentful/contentful.types";

export enum EAccountSubmenuIdentifiers {
	BlockAccountSubmenu = "block_account-submenu",
	TranslationAccountSubmenuHello = "translation_account-submenu-hello",
	LinkMyAccountMenuProfile = "link_my-account-menu-profile",
	LinkMyAccountMenuAddress = "link_my-account-menu-address",
	LinkMyAccountMenuPurchaseHistory = "link_my-account-menu-purchase-history",
	TranslationAccountSubmenuLogout = "translation_account-submenu-logout",
}

export interface IAccountSubmenuVariables {
	identifier: EAccountSubmenuIdentifiers;
}

export type TAccountSubmenuData = IContentBlockCollection<
	IContentBlockCflData<ILinkCflData | ITranslationKeyCflData>
>;
