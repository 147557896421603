import { SyntheticEvent, useRef } from "react";
import { signIn } from "next-auth/react";
import Button from "@components/Button/Button.component";
import { EButtonSize, EButtonType } from "@components/Button/types";
import {
	EComponentLinkType,
	ILinkCflData,
} from "@components/ContentfulComponents/ComponentLink/type";
import { convertRelOptions, convertTargetOptions } from "@shared/contentful/contentful.utils";
import { EDataLayerEventType } from "@shared/datalayer/datalayer.types";
import { dispatchDataLayerEvent } from "@shared/datalayer/DataLayer";

import styles from "@components/Header/Components/PanelNotConnected/ConnectionAdvantages/ConnectionAdvantages.module.scss";

export interface IConnectionLinkProps {
	data: ILinkCflData;
}

const ConnectionLink = ({ data }: IConnectionLinkProps) => {
	const isLoginCalled = useRef(false);

	if (!data?.url || !data.label) return null;
	const { label, url, type, targetOptions, relOptions } = data;
	const buttonType =
		type === EComponentLinkType.Secondary ? EButtonType.Secondary : EButtonType.Primary;

	const ctaRel = convertRelOptions(relOptions);
	const ctaTarget = convertTargetOptions(targetOptions);

	return (
		<Button
			href={url}
			buttonSize={EButtonSize.Md}
			as="a"
			buttonType={buttonType}
			target={ctaTarget}
			rel={ctaRel}
			className={styles.cta}
			onClick={(e: SyntheticEvent<HTMLAnchorElement, Event>) => {
				e.preventDefault();

				if (isLoginCalled.current) return;

				isLoginCalled.current = true;

				dispatchDataLayerEvent(EDataLayerEventType.MenuLoginPanelClick);
				signIn("auth0");
			}}
		>
			{label}
		</Button>
	);
};

export default ConnectionLink;
