interface IProps {
	fillColor: string;
}

const Beak = ({ fillColor }: IProps) => {
	return (
		<svg width="24" height="8" viewBox="0 0 26 7" fill="none" xmlns="https://www.w3.org/2000/svg">
			<path
				d="M15.8284 1.17156L18.6569 3.99999C20.1571 5.50028 22.192 6.34314 24.3137 6.34314L1.68629 6.34314C3.80802 6.34314 5.84285 5.50029 7.34314 4L10.1716 1.17157C11.7337 -0.39053 14.2663 -0.390532 15.8284 1.17156Z"
				fill={fillColor}
			/>
		</svg>
	);
};

export default Beak;
