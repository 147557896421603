import React from "react";
import dynamic from "next/dynamic";

import {
	ERichTextCflDisplayOptions,
	IRichTextCflData,
} from "@components/ContentfulComponents/ComponentRichText/type";
import { RenderMark, RenderNode } from "@contentful/rich-text-react-renderer";
import { renderRichTextWithLinks } from "@shared/contentful/contentful-render.utils";

interface IProps {
	data: IRichTextCflData;
	additionalRenderNodeOptions?: RenderNode;
	additionalRenderMarkOptions?: RenderMark;
}

const DynamicHeadline = dynamic(
	() => import("@components/ContentfulComponents/ComponentRichText/Headline/Headline.component")
);
const DynamicInfoMessage = dynamic(
	() =>
		import("@components/ContentfulComponents/ComponentRichText/InfoMessage/InfoMessage.component")
);

const RichText = ({ data, additionalRenderNodeOptions, additionalRenderMarkOptions }: IProps) => {
	if (!data) return null;

	const { displayAs, openLinksInANewTab, richTextContent } = data;

	if (!richTextContent) return null;

	switch (displayAs) {
		case ERichTextCflDisplayOptions.Headline:
			return (
				<DynamicHeadline
					json={richTextContent}
					openLinksInANewTab={openLinksInANewTab}
					data-testid="RichText_headline"
				/>
			);
		case ERichTextCflDisplayOptions.InfoMessage:
			return (
				<DynamicInfoMessage
					json={richTextContent}
					openLinksInANewTab={openLinksInANewTab}
					data-testid="RichText_info-message"
				/>
			);
		case ERichTextCflDisplayOptions.Regular:
			return (
				<>
					{renderRichTextWithLinks({
						richTextDocument: richTextContent.json,
						openLinksInANewTab,
						additionalRenderNodeOptions,
						additionalRenderMarkOptions,
					})}
				</>
			);
		default:
			return null;
	}
};

export default RichText;
