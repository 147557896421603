import { IContentBlockCflData } from "@components/ContentfulComponents/BlockContentBlock/type";
import { ILinkCflData } from "@components/ContentfulComponents/ComponentLink/type";
import { IContentBlockCollection } from "@shared/contentful/contentful.types";
import { ReactNode } from "react";

export enum EMyAccountMenuIdentifiers {
	BlockMyAccountMenu = "block_my-account-menu",
	LinkMyAccountMenuDashboard = "link_my-account-menu-dashboard",
	LinkMyAccountMenuPurchaseHistory = "link_my-account-menu-purchase-history",
	LinkMyAccountMenuProfile = "link_my-account-menu-profile",
	LinkMyAccountMenuAddress = "link_my-account-menu-address",
	LinkMyAccountMenuCommunications = "link_my-account-menu-communications",
}

export interface IMyAccountMenuVariables {
	identifier: EMyAccountMenuIdentifiers;
}

export type TMyAccountMenuData = IContentBlockCollection<IContentBlockCflData<ILinkCflData>>;

export interface IMyAccountLayout {
	children: ReactNode;
	pageTitle: string;
	absoluteUrl?: string; // TODO remove canonical on all my account pages to remove this props
}
