import Link from "next/link";
import { useSelector } from "react-redux";
import { selectTranslationStore } from "@redux/translation/translation.selector";

import { IPropsComponent } from "../type";

import { ELayoutIdentifiers } from "@components/Layout/type";
import Icon, { EIcon } from "@components/Icon/Icon.component";
import { useAbsolute } from "@hooks/useAbsolute";

const Store = (props: IPropsComponent) => {
	const translations = useSelector(selectTranslationStore);
	const { absoluteUrl } = useAbsolute("stores");

	const ariaIconStore = translations[ELayoutIdentifiers.AriaIconStores] || "all stores";

	return (
		<Link
			href={absoluteUrl}
			rel="follow"
			data-testid="Store_open-btn"
			aria-label={ariaIconStore}
			{...props}
		>
			<Icon icon={EIcon.MapPin} aria-label={ariaIconStore} />
		</Link>
	);
};

export default Store;
