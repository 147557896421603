import {
	DEFAULT_DELETE_COOKIE_OPTIONS,
	deleteCookie,
	ECookieName,
	getCookie,
	PROFILE_SET_COOKIE_OPTIONS,
	setCookie,
	TCookieContextRequest,
	TCookieContextResponse,
} from "@utils/cookie.utils";

const cookiesOptionsRedirect = {
	maxAge: 5 * 60,
	path: "/",
};

/* Cookie profile
 ================================= */
export const setFirstNameInCookies = (res: TCookieContextResponse, firstName: string) => {
	setCookie(ECookieName.FirstName, firstName, res, PROFILE_SET_COOKIE_OPTIONS);
};

export const getFirstNameInCookies = (req?: TCookieContextRequest) => {
	return getCookie(ECookieName.FirstName, req);
};

export const deleteFirstNameInCookies = (res: TCookieContextResponse) => {
	deleteCookie(ECookieName.FirstName, res, DEFAULT_DELETE_COOKIE_OPTIONS);
};

/* Cookie redirect
 ================================= */
export const setRedirectURLInCookies = (url: string, res?: TCookieContextResponse) => {
	setCookie(ECookieName.UserRedirect, url, res, cookiesOptionsRedirect);
};
export const getRedirectURLInCookies = (req?: TCookieContextRequest) => {
	return getCookie(ECookieName.UserRedirect, req);
};
export const deleteRedirectURLInCookies = (res?: TCookieContextResponse) => {
	deleteCookie(ECookieName.UserRedirect, res, cookiesOptionsRedirect);
};
