import { IAssetCflData } from "@components/ContentfulComponents/ComponentAsset/type";
import { getPropsForImages } from "@components/CustomImage/custom-images.utils";
import Image from "next/legacy/image";

import styles from "@components/Legal/Legal.module.scss";

interface IPaymentIcons {
	assets: IAssetCflData[];
}

const PaymentIcons = ({ assets }: IPaymentIcons) => {
	if (!assets?.length) return null;

	return (
		<div className={styles.iconsWrapper}>
			{assets.map(({ asset, identifier }) => {
				const imageProps =
					asset &&
					getPropsForImages({
						image: asset,
						slot: "34px",
					});
				if (!imageProps) return null;
				return (
					<div key={identifier} className={styles.icons}>
						<Image
							alt={imageProps.alt}
							src={imageProps.url}
							layout="responsive"
							width={imageProps.initialWidth}
							height={imageProps.initialHeight}
							objectFit="cover"
							sizes={imageProps.slot}
							priority={imageProps.priority}
						/>
					</div>
				);
			})}
		</div>
	);
};

export default PaymentIcons;
