import { IContentBlockCflData } from "@components/ContentfulComponents/BlockContentBlock/type";
import { ILinkCflData } from "@components/ContentfulComponents/ComponentLink/type";
import { ITranslationKeyCflData } from "@components/ContentfulComponents/ComponentTranslationKey/type";
import { EBlockTypenames, EComponentTypenames } from "@shared/contentful/contentful.types";
import ConnectionLink from "@components/Header/Components/PanelNotConnected/ConnectionLink/ConnectionLink.component";
import AdvantagesContent from "@components/Header/Components/PanelNotConnected/ConnectionAdvantages/AdvantagesContent/AdvantagesContent.component";

import styles from "@components/Header/Components/PanelNotConnected/ConnectionAdvantages/ConnectionAdvantages.module.scss";

export interface IConnectionAdvantagesProps {
	data: IContentBlockCflData<IContentBlockCflData<ITranslationKeyCflData> | ILinkCflData>;
}

const ConnectionAdvantages = ({ data }: IConnectionAdvantagesProps) => {
	if (!data?.contentCollection?.items?.length) return null;

	const { label, contentCollection } = data;

	return (
		<div className={styles.connectionAdvantagesWrapper} data-testid="ConnectionAdvantages_wrapper">
			{label && <h3 data-testid="ConnectionAdvantages_label">{label}</h3>}
			{contentCollection.items.map((item) => {
				switch (item.__typename) {
					case EBlockTypenames.ContentBlock:
						return <AdvantagesContent data={item} key={item.sys?.id} />;
					case EComponentTypenames.Link:
						return <ConnectionLink data={item} key={item.sys?.id} />;
					default:
						return null;
				}
			})}
		</div>
	);
};

export default ConnectionAdvantages;
