import FocusTrap from "focus-trap-react";
import React, {
	ReactNode,
	useEffect,
	HTMLAttributes,
	useState,
	Dispatch,
	SetStateAction,
} from "react";
import ReactDOM from "react-dom";
import { useDispatch } from "react-redux";
import { Transition } from "react-transition-group";

import { displayShadowOnScroll } from "@components/Menu/MenuView.utils";
import { useResponsiveOnLoad } from "@hooks/useDevice";
import useHandleClosePortal from "@hooks/useHandleClosePortal.hook";
import { toggleScroll } from "@redux/misc/misc.reducer";

import panelSlideAnimations, {
	animationDuration,
	PanelTransitionAnimation,
} from "./PanelSlideAnimations";

import styles from "./PanelSlide.module.scss";

export enum EDirection {
	Center = "panelSlideCenter",
	Left = "panelSlideLeft",
	Right = "panelSlideRight",
	Bottom = "panelSlideBottom",
}
interface IPanelSlide extends HTMLAttributes<HTMLDivElement> {
	position: EDirection;
	children: ReactNode;
	isOpen: boolean;
	panelName: string;
	width?: string;
	height?: string;
	className?: string;
	classNameWrapper?: string;
	classNameContent?: string;
	delay?: number;
	customAnimation?: Record<string, Partial<PanelTransitionAnimation>>;
	unmountOnExit?: boolean;
	toggleOpen: (showPanelSlide?: boolean) => void;
	setShowShadow?: Dispatch<SetStateAction<boolean>>;
	isFullyDisplayed?: boolean;
	initialFocus?: HTMLElement | SVGElement | false;
}

const PanelSlide = ({
	position = EDirection.Center,
	width,
	height,
	children,
	isOpen,
	panelName,
	toggleOpen,
	classNameWrapper,
	classNameContent,
	className = "",
	customAnimation,
	delay,
	unmountOnExit = true,
	setShowShadow,
	isFullyDisplayed,
	initialFocus,
}: IPanelSlide) => {
	const animation = customAnimation?.[position] ?? panelSlideAnimations[position];
	const overlayAnimation = customAnimation?.["overlay"] ?? panelSlideAnimations["overlay"];

	const dispatch = useDispatch();

	const [lastActiveElement, setLastActiveElement] = useState<HTMLElement | null>(null);

	const { isLoad } = useResponsiveOnLoad();
	const parentDiv = useHandleClosePortal<HTMLDivElement>(isOpen ? toggleOpen : () => null);

	useEffect(() => {
		return () => {
			dispatch(toggleScroll(true));
		};
	}, []);

	useEffect(() => {
		if (delay && isOpen) {
			const interval = setInterval(() => {
				toggleOpen();
			}, delay);
			return () => clearInterval(interval);
		}
	}, [isOpen]);

	useEffect(() => {
		if (isOpen) {
			setLastActiveElement(document.activeElement as HTMLElement);
		} else {
			lastActiveElement?.focus();
		}

		dispatch(toggleScroll(!isOpen));
	}, [isOpen]);

	if (!isLoad) {
		return null;
	}

	return (
		<>
			{ReactDOM.createPortal(
				<Transition
					in={isOpen}
					classNames="panelSlide"
					timeout={animationDuration}
					unmountOnExit={unmountOnExit}
				>
					{(state) => (
						<FocusTrap active={state === "entered"} focusTrapOptions={{ initialFocus }}>
							<div>
								<aside
									data-testid="PanelSlide_wrapper"
									className={`${classNameWrapper || styles.panelSlideWrapper} ${className}`}
									style={animation[state]}
									role={"dialog"}
									aria-modal={true}
									aria-label={panelName}
									onScroll={(e) => {
										if (setShowShadow && !isFullyDisplayed) displayShadowOnScroll(e, setShowShadow);
									}}
								>
									<div
										data-testid="PanelSlide_container"
										id={"content"}
										className={`${styles.panelSlideGroupWrapper} ${classNameContent ?? ""}`}
										style={{ width, height }}
										ref={parentDiv}
									>
										{children}
									</div>
								</aside>
								<div
									data-testid="PanelSlide_overlay"
									role="button"
									className={styles.overlay}
									style={overlayAnimation[state]}
									tabIndex={0}
									aria-label="close"
								/>
							</div>
						</FocusTrap>
					)}
				</Transition>,
				document.body
			)}
		</>
	);
};

export default PanelSlide;
