import {
	cleanPixlId,
	defineVideoOptions,
	EVideoOptions,
} from "@components/ContentfulComponents/ComponentAsset/utils/asset.utils";
import getConfig from "next/config";
import React from "react";

interface IPixlVideo {
	diffusionPixlId?: string | null;
	videoTitle?: string | null;
	videoOptions?: EVideoOptions[] | null;
	className?: string;
}

const {
	publicRuntimeConfig: {
		pixl: { playerId, brightcoveAccountId },
	},
} = getConfig();

const PixlVideo = ({ diffusionPixlId, className, videoOptions, videoTitle }: IPixlVideo) => {
	if (!diffusionPixlId || !videoTitle) return null;

	const { autoplayVideo } = defineVideoOptions(videoOptions);

	const autoplayOption = autoplayVideo ? "&autoplay&muted" : "";

	return (
		<iframe
			src={`//players.brightcove.net/${brightcoveAccountId}/${playerId}/index.html?videoId=ref:${cleanPixlId(
				diffusionPixlId
			)}${autoplayOption}`}
			className={className ?? ""}
			data-testid="PixlVideo_iframe"
			title={videoTitle}
			loading="lazy"
		/>
	);
};

export default PixlVideo;
