import Icon, { EIcon } from "@components/Icon/Icon.component";
import { ELayoutIdentifiers } from "@components/Layout/type";
import useLocale from "@hooks/useLocale";
import { useSelector } from "react-redux";
import { selectTranslationStore } from "@redux/translation/translation.selector";
import { IQuerySuggestions } from "./types";
import { globalDataLayer } from "@shared/datalayer/DataLayer";
import { buildSearchSuggestionDataLayer } from "@shared/datalayer/search-suggestion-datalayer/search-suggestion-datalayer";
import { useAbsolute } from "@hooks/useAbsolute";

interface ISearchSuggestionEntry {
	query: string;
	locale: string;
	search: string;
	count: number;
}

export const SearchSuggestionEntry = ({ query, locale, search, count }: ISearchSuggestionEntry) => {
	const { absoluteUrl } = useAbsolute(`/search?query=${query}`);
	const handleClick = () => {
		const searchSuggestion = buildSearchSuggestionDataLayer({
			count,
			term: search,
			type: "text",
			url: absoluteUrl,
		});

		globalDataLayer.customDataLayer.dispatchData(searchSuggestion);
	};

	return (
		<li className="aa-ItemWrapper" key={query}>
			<a
				href={`/${locale}/search?query=${query}`}
				data-testid="SearchSuggestionEntry_click"
				onClick={handleClick}
			>
				<Icon icon={EIcon.Search} noHover size={16} />
				{query}
			</a>
		</li>
	);
};

export const SearchSuggestionTitle = () => {
	const translations = useSelector(selectTranslationStore);
	return (
		<p className="aa-SourceHeaderTitle" id="searchQuerySuggestionsList">
			{translations[ELayoutIdentifiers.TitleSearchQuerySuggestions]}
		</p>
	);
};

const SearchQuerySuggestions = ({ autocompleteState, results }: IQuerySuggestions) => {
	const { locale } = useLocale();
	if (!autocompleteState?.isOpen || !autocompleteState.collections?.length) return null;
	return (
		<>
			{autocompleteState.collections.map(({ source, items }) => {
				if (!source || source.sourceId !== "querySuggestions" || !items?.length) return;
				return (
					<div
						key={source.sourceId}
						className="aa-Source"
						data-testid="SearchQuerySuggestions_wrapper"
					>
						<SearchSuggestionTitle />

						<ul className="aa-List" aria-labelledby="searchQuerySuggestionsList">
							{items.map((item) => (
								<SearchSuggestionEntry
									query={item?.query}
									locale={locale}
									key={item?.query}
									search={autocompleteState.query}
									count={results}
								/>
							))}
						</ul>
					</div>
				);
			})}
		</>
	);
};

export default SearchQuerySuggestions;
