import Link from "next/link";
import React from "react";

import { useAbsolute } from "@hooks/useAbsolute";
import { useRelOptionsFollowHome } from "@hooks/useFollowHome";
import { convertTargetOptions } from "@shared/contentful/contentful.utils";
import { ILinkCflData } from "@components/ContentfulComponents/ComponentLink/type";

import styles from "./Legal.module.scss";

interface ILegalLinkProps {
	link: ILinkCflData;
}

const LegalLink = ({ link }: ILegalLinkProps) => {
	const { absoluteUrl } = useAbsolute(link?.url);
	const rel = useRelOptionsFollowHome(link?.relOptions);
	const target = convertTargetOptions(link?.targetOptions);

	if (!link || !absoluteUrl) {
		return null;
	}

	const { label } = link;

	return (
		<div className={styles.linkWrapper} data-testid="LegalLink_wrapper">
			<Link
				href={absoluteUrl}
				rel={rel}
				data-testid="LegalLink_cta-link"
				data-label={label}
				target={target}
			>
				{label}
			</Link>
		</div>
	);
};

export default LegalLink;
