import useGraphQL from "@hooks/useGraphql";
import {
	ESocialMediaIdentifier,
	ISocialMediaVariables,
	socialMediaQuery,
	TSocialMediaData,
} from "./social-media.query";
import SocialMediaLink from "./SocialMediaLink.component";
import styles from "./SocialMedia.module.scss";

const SocialMedia = () => {
	const { data: socialMediaData } = useGraphQL<TSocialMediaData, ISocialMediaVariables>(
		socialMediaQuery,
		{
			variables: { identifier: ESocialMediaIdentifier.BlockSocialMedia },
		}
	);

	if (!socialMediaData?.contentBlockCollection?.items[0]) {
		return null;
	}

	const links = socialMediaData.contentBlockCollection.items[0].contentCollection?.items;

	if (!links) {
		return null;
	}

	return (
		<div className={styles.socialMediaWrapper} data-testid="SocialMedia_wrapper">
			{links.map((link) => {
				return <SocialMediaLink link={link} key={link.identifier} />;
			})}
		</div>
	);
};

export default SocialMedia;
