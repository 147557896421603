import { useAbsolute } from "@hooks/useAbsolute";

import { convertRelOptions, convertTargetOptions } from "@shared/contentful/contentful.utils";
import { replaceAllBreakSpaces } from "@shared/text/text.utils";

import { IFooterLinkProps } from "./FooterLinks.type";

import styles from "./FooterLinks.module.scss";
import { isEmpty } from "lodash";

const FooterLinkLi = ({ data, isBold = false }: IFooterLinkProps) => {
	if (!data || isEmpty(data)) return null;
	const { url, label, relOptions, targetOptions } = data;
	const rel = convertRelOptions(relOptions);
	const target = convertTargetOptions(targetOptions);

	const { absoluteUrl } = useAbsolute(url);

	if (!absoluteUrl) return null;

	return (
		<li className={isBold ? styles.liBold : ""} data-testid="FooterLinkLi_list">
			<a href={absoluteUrl} target={target} rel={rel}>
				{replaceAllBreakSpaces(label)}
			</a>
		</li>
	);
};

export default FooterLinkLi;
