import useLocale from "@hooks/useLocale";
import { displayPriceWithCurrency } from "@shared/product/product.utils";
import { IPrice } from "./ProductPrice.types";

import styles from "@components/ProductInfo/ProductInfo.module.scss";

const FinalPrice = ({ price, className }: IPrice) => {
	const { locale } = useLocale();
	return (
		<div className={`${styles.priceWrapper} ${className}`}>
			<p className={styles.regularPrice}>{displayPriceWithCurrency(price?.finalPrice, locale)}</p>
		</div>
	);
};

export default FinalPrice;
