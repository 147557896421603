import React from "react";

import Icon, { EIcon } from "../Icon/Icon.component";
import {
	EScrollToTopIdentifier,
	IScrollToTopVariables,
	scrollToTopQuery,
} from "./ScrollToTop.query";

import { useScroll } from "@hooks/useScroll";
import useGraphQL from "@hooks/useGraphql";
import theme from "@shared/styles/theme";
import { TContentTranslation } from "@shared/types/translations.types";
import { getContentTranslations } from "@shared/contentful/translations.utils";
import { ITranslationKeyCollection } from "@shared/contentful/contentful.types";
import { scrollToTarget } from "@utils/scrollToTarget.utils";

import styles from "./ScrollToTop.module.scss";

const { black } = theme.colors;

const ScrollToTopDesktop = () => {
	const isShowScrollButton = useScroll(4500);

	const handlerClick = () => {
		scrollToTarget();
	};

	const { data } = useGraphQL<ITranslationKeyCollection, IScrollToTopVariables>(scrollToTopQuery, {
		variables: { identifier: EScrollToTopIdentifier.TextBackToTop },
	});

	if (!data?.translationKeyCollection?.items?.length) return null;

	const translations: TContentTranslation<string> = getContentTranslations(
		data.translationKeyCollection.items
	);

	const scrollToTopTranslation =
		translations?.[EScrollToTopIdentifier.TextBackToTop] || "back to top";

	return (
		<div
			data-testid="ScrollToTopDesktop_wrapper"
			className={`${styles.scrollToTopDesktopWrapper} ${
				isShowScrollButton ? styles.block : "hidden"
			}`}
		>
			<button
				onClick={handlerClick}
				data-testid="ScrollToTopDesktop_btn"
				aria-label={scrollToTopTranslation}
			>
				<Icon icon={EIcon.ArrowUp} color={black} aria-label={scrollToTopTranslation} />
			</button>
		</div>
	);
};

export default ScrollToTopDesktop;
