import { UNMOUNTED, EXITED, ENTERING, ENTERED, EXITING } from "react-transition-group/Transition";

export type PanelTransitionAnimation = {
	[EXITED]: Record<string, string | number>;
	[ENTERING]: Record<string, string | number>;
	[ENTERED]: Record<string, string | number>;
	[EXITING]: Record<string, string | number>;
	[UNMOUNTED]?: Record<string, string | number>;
};

export type CustomPanelTransitionAnimation = Partial<PanelTransitionAnimation>;

export const animationDuration = 300;
export const defaultEaseOut = `all ${animationDuration}ms ease-out`;
export const defaultEaseIn = `all ${animationDuration}ms ease-in`;

const panelSlideCenter: PanelTransitionAnimation = {
	entering: { opacity: 1, transition: defaultEaseOut },
	entered: { opacity: 1, transition: defaultEaseOut },
	exiting: { opacity: 0, transition: defaultEaseIn },
	exited: { opacity: 0, transition: defaultEaseIn },
};

const panelSlideRight: PanelTransitionAnimation = {
	entering: { top: 0, right: 0, transition: defaultEaseOut },
	entered: { top: 0, right: 0, transition: defaultEaseOut },
	exiting: { top: 0, right: "-30.5rem", transition: defaultEaseIn },
	exited: { top: 0, right: "-30.5rem", transition: defaultEaseIn },
};

const panelSlideLeft: PanelTransitionAnimation = {
	entering: { top: 0, left: 0, transition: defaultEaseOut },
	entered: { top: 0, left: 0, transition: defaultEaseOut },
	exiting: { top: 0, left: "min(-85vw, -320px)", transition: defaultEaseIn },
	exited: { top: 0, left: "min(-85vw, -320px)", transition: defaultEaseIn },
};

const panelSlideBottom: PanelTransitionAnimation = {
	entering: { bottom: 0, transition: defaultEaseOut },
	entered: { bottom: 0, transition: defaultEaseOut },
	exiting: { bottom: "-50vh", transition: defaultEaseIn },
	exited: { bottom: "-50vh", transition: defaultEaseIn },
};

const overlay: PanelTransitionAnimation = {
	entering: { opacity: 1, backdropFilter: "blur(4px)", transition: defaultEaseOut },
	entered: { opacity: 1, backdropFilter: "blur(4px)", transition: defaultEaseOut },
	exiting: { opacity: 0, backdropFilter: "blur(0px)", transition: defaultEaseIn },
	exited: { opacity: 0, backdropFilter: "blur(0px)", transition: defaultEaseIn },
};

export default {
	panelSlideCenter,
	panelSlideRight,
	panelSlideLeft,
	panelSlideBottom,
	overlay,
};
