import { IContentBlockCflData } from "@components/ContentfulComponents/BlockContentBlock/type";
import { IAssetCflData } from "@components/ContentfulComponents/ComponentAsset/type";
import { ILinkCflData } from "@components/ContentfulComponents/ComponentLink/type";
import { ITranslationKeyCflData } from "@components/ContentfulComponents/ComponentTranslationKey/type";
import { isLink } from "@shared/contentful/contentful.utils";
import { TContentTranslation } from "@shared/types/translations.types";
import { isEmpty } from "lodash";
import { ELegalTranslations, TLegalDataItems } from "./legal.query";

export const filterLegalLinks = (translations: TContentTranslation<TLegalDataItems>) => {
	if (!translations || isEmpty(translations)) return;
	return Object.values(translations).filter((item) => isLink(item)) as ILinkCflData[];
};

export const findPaymentOptionsContent = (collectionItems: TLegalDataItems[]) => {
	const paymentOptionsContent = collectionItems?.find(
		(item) => item?.identifier === ELegalTranslations.BlockCartCheckoutFooter
	);
	if (!paymentOptionsContent) return;
	return paymentOptionsContent as IContentBlockCflData<ITranslationKeyCflData | IAssetCflData>;
};
