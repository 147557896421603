import { gql } from "graphql-request";
import { IContentBlockCflData } from "@components/ContentfulComponents/BlockContentBlock/type";
import {
	assetFragment,
	componentAssetFragment,
} from "@components/ContentfulComponents/ComponentAsset/fragment";
import { IAssetCflData } from "@components/ContentfulComponents/ComponentAsset/type";
import { componentLinkWithIconFragment } from "@components/ContentfulComponents/ComponentLink/fragment";
import { ILinkCflData } from "@components/ContentfulComponents/ComponentLink/type";
import { componentRichTextFragment } from "@components/ContentfulComponents/ComponentRichText/fragment";
import { IRichTextCflData } from "@components/ContentfulComponents/ComponentRichText/type";
import { componentTranslationKeyFragment } from "@components/ContentfulComponents/ComponentTranslationKey/fragment";
import { ITranslationKeyCflData } from "@components/ContentfulComponents/ComponentTranslationKey/type";
import { IContentBlockCollection } from "@shared/contentful/contentful.types";

export type TLegalDataItems =
	| IRichTextCflData
	| IContentBlockCflData<ILinkCflData | ITranslationKeyCflData | IAssetCflData>;

export type TLegalData = IContentBlockCollection<TLegalDataItems>;

export enum ELegalTranslations {
	BlockLegal = "block_legal",
	TextCopyright = "text_copyright",
	TranslationSecurePayments = "translation_secure-payments",
	BlockCartCheckoutFooter = "block_cart-checkout-footer",
}

export interface ILegalVariables {
	identifier: ELegalTranslations;
	limit: number;
}

export const legalQuery = gql`
	${componentRichTextFragment}
	${componentTranslationKeyFragment}
	${componentLinkWithIconFragment}
	${componentAssetFragment}
	${assetFragment}
	query legal($locale: String, $identifier: String, $limit: Int) {
		contentBlockCollection(where: { identifier: $identifier }, locale: $locale, limit: $limit) {
			items {
				__typename
				identifier
				contentCollection(limit: 10) {
					items {
						__typename
						...richText
						... on ContentBlock {
							identifier
							label
							contentCollection(limit: 7) {
								items {
									...linkIcon
									...translationKey
									...asset
								}
							}
						}
					}
				}
			}
		}
	}
`;
