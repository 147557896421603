import { replaceAllBreakSpaces } from "@shared/text/text.utils";
import React from "react";
import styles from "./FooterLinks.module.scss";

interface IProps {
	label: string | undefined;
}

const FooterLinksTitleDesktop = ({ label }: IProps) => {
	if (!label) return null;
	return (
		<p className={styles.groupName} data-testid="FooterLinksTitleDesktop_wrapper">
			<strong>{replaceAllBreakSpaces(label)}</strong>
		</p>
	);
};

export default FooterLinksTitleDesktop;
