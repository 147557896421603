import Promise from "./Promise.component";
import styles from "./Promises.module.scss";
import useGraphQL from "@hooks/useGraphql";
import {
	EPromiseIdentifier,
	IPromiseQueryIdentifiers,
	promisesQuery,
	TPromisesQueryDaya,
} from "./Promises.query";

const Promises = () => {
	const { data } = useGraphQL<TPromisesQueryDaya, IPromiseQueryIdentifiers>(promisesQuery, {
		variables: { identifier: EPromiseIdentifier.BlockFooterPromises },
	});

	if (!data?.contentBlockCollection) return null;

	const { items } = data.contentBlockCollection.items[0]?.contentCollection || [];

	return (
		<>
			{items && items.length > 0 && (
				<div className={`${styles.promisesWrapper}`} data-testid="Promises_wrapper">
					{items.map((item) => {
						return <Promise {...item} key={item?.sys?.id} />;
					})}
				</div>
			)}
		</>
	);
};

export default Promises;
