import { gql } from "graphql-request";

import { componentLinkFragment } from "@components/ContentfulComponents/ComponentLink/fragment";
import { ILinkCollection } from "@shared/contentful/contentful.types";

export enum ENewMemberLinkIdentifier {
	newMemberLink = "link_new-membership",
}

export interface INewMemberLinkVariables {
	identifier: ENewMemberLinkIdentifier;
}

export type TNewMemberLinkData = ILinkCollection;

export const newMemberLinkQuery = gql`
	${componentLinkFragment}
	query newMemberLink($identifier: String, $locale: String) {
		linkCollection(where: { identifier: $identifier }, locale: $locale) {
			items {
				...link
			}
		}
	}
`;
