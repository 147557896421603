import { gql } from "graphql-request";

import { componentTranslationKeyFragment } from "@components/ContentfulComponents/ComponentTranslationKey/fragment";
import { ITranslationKeyCflData } from "@components/ContentfulComponents/ComponentTranslationKey/type";
import { IContentBlockCollection } from "@shared/contentful/contentful.types";

export enum EPreviewIdentifiers {
	BlockPreviewContent = "block_preview-mode-content",
	TranslationTitle = "translation_preview-mode-title",
	TranslationLinkLabel = "translation_preview-mode-link-label",
}

export interface IPreviewVariables {
	identifier: EPreviewIdentifiers;
}

export type TPreviewContentData = IContentBlockCollection<ITranslationKeyCflData>;

export const previewQuery = gql`
	${componentTranslationKeyFragment}
	query previewModeContent($identifier: String, $locale: String) {
		contentBlockCollection(where: { identifier: $identifier }, limit: 1, locale: $locale) {
			items {
				contentCollection {
					items {
						...translationKey
					}
				}
			}
		}
	}
`;
