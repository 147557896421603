import { gql } from "graphql-request";
import { componentLinkWithIconFragment } from "@components/ContentfulComponents/ComponentLink/fragment";
import { componentTextWithLinkFragment } from "@components/ContentfulComponents/ComponentTextWithLink/fragment";

export enum EFooterSeoIdentifiers {
	FooterSeo = "text_seo-footer-text",
}

export interface IFooterSeoVariables {
	identifier: EFooterSeoIdentifiers;
}

export const footerSeoTextQuery = gql`
	${componentLinkWithIconFragment}
	${componentTextWithLinkFragment}
	query footerSEO($identifier: String, $locale: String) {
		textWithLinkCollection(where: { identifier: $identifier }, locale: $locale) {
			items {
				...textWithLink
			}
		}
	}
`;
