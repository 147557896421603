import { gql } from "graphql-request";
import { IContentBlockCflData } from "@components/ContentfulComponents/BlockContentBlock/type";
import { componentLinkFragment } from "@components/ContentfulComponents/ComponentLink/fragment";
import { ILinkCflData } from "@components/ContentfulComponents/ComponentLink/type";
import { componentTranslationKeyFragment } from "@components/ContentfulComponents/ComponentTranslationKey/fragment";
import { ITranslationKeyCflData } from "@components/ContentfulComponents/ComponentTranslationKey/type";
import { IContentBlockCollection } from "@shared/contentful/contentful.types";
import {
	assetFragment,
	componentAssetFragment,
} from "@components/ContentfulComponents/ComponentAsset/fragment";
import { IAssetCflData } from "@components/ContentfulComponents/ComponentAsset/type";

export enum EPanelNotConnectedIdentifiers {
	NotConnectedPanel = "header_account-not-connected-panel",
}

export type TPanelNotConnected = IContentBlockCollection<
	IAssetCflData | IContentBlockCflData<ILinkCflData | IContentBlockCflData<ITranslationKeyCflData>>
>;

export const panelNotConnectedQuery = gql`
	${componentLinkFragment}
	${componentTranslationKeyFragment}
	${componentAssetFragment}
	${assetFragment}
	query panelNotConnectedQuery($locale: String, $identifier: String) {
		contentBlockCollection(where: { identifier: $identifier }, locale: $locale, limit: 1) {
			items {
				contentCollection(limit: 5) {
					__typename
					items {
						...asset
						... on ContentBlock {
							__typename
							label
							sys {
								id
							}
							contentCollection(limit: 5) {
								items {
									...link
									... on ContentBlock {
										__typename
										sys {
											id
										}
										contentCollection(limit: 6) {
											items {
												...translationKey
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;
