import { isDate } from "lodash";
import { isYearMonthDay } from "@utils/date.utils";
import { defaultLocale, otherLocale } from "@config/languages";
import { replaceTermInTranslation } from "./translations.utils";

interface IFormatTimeForFulfillment {
	translation: {
		deliveryToday?: string;
		hours?: string;
	};
	dateContent?: string;
	isLowerCase?: boolean;
	timeForFulfillment?: string | Date;
}

export const englishOrdinalSuffix = (dt: Date) => {
	if (dt.getDate() % 10 == 1 && dt.getDate() != 11) {
		return "st";
	}
	if (dt.getDate() % 10 == 2 && dt.getDate() != 12) {
		return "nd";
	}
	if (dt.getDate() % 10 == 3 && dt.getDate() != 13) {
		return "rd";
	}
	return "th";
};

export const getTextEllipsis = (text: string, threshold: number) => {
	return text.length > threshold ? text.substring(0, threshold) + "..." : text;
};

export const formatPurchaseHistoryDate = ({
	date,
	locale = "en",
}: {
	date?: string;
	locale?: string;
}) => {
	if (!date) return "[date]";
	return new Date(date).toLocaleString(locale, { month: "short", day: "2-digit", year: "numeric" });
};

export const formatYearMonthDay = (date: string) => {
	return isYearMonthDay(date) ? `${date}T00:00:00` : date;
};

export const formatOrderDate = (date?: Date | string, locale = "en") => {
	if (!date) return;
	const orderDate = isDate(date) ? date : new Date(formatYearMonthDay(date));
	return orderDate.toLocaleDateString(locale, {
		weekday: "short",
		year: "numeric",
		month: "short",
		day: "2-digit",
	});
};

export const formatShippingDate = (date?: Date | string, locale = defaultLocale) => {
	if (!date) return "";
	const orderDate = isDate(date) ? date : new Date(formatYearMonthDay(date));
	return orderDate.toLocaleDateString(locale, {
		weekday: "short",
		month: "short",
		day: "2-digit",
	});
};

export const formatMonthDay = (date?: Date | string, locale = defaultLocale) => {
	if (!date) return;
	const orderDate = isDate(date) ? date : new Date(formatYearMonthDay(date));
	return orderDate.toLocaleDateString(locale, {
		month: "short",
		day: "2-digit",
	});
};

export const formatTimeForFulfillment = ({
	dateContent,
	timeForFulfillment,
	translation,
	isLowerCase,
}: IFormatTimeForFulfillment) => {
	if (!timeForFulfillment) return;

	if (isDate(timeForFulfillment)) return dateContent;

	const { deliveryToday, hours } = translation;

	if (deliveryToday) return deliveryToday;

	const formattedTranslation = replaceTermInTranslation({
		translation: hours,
		genericTerm: "timeForFulfillment",
		newTerm: timeForFulfillment,
	});

	if (isLowerCase) return formattedTranslation?.toLowerCase();

	return formattedTranslation;
};

export const formatLocaleCA = (locale?: string) => (locale === otherLocale ? "fr-CA" : "en-CA");
