/* eslint-disable @typescript-eslint/no-var-requires */
import React from "react";
import { Lock } from "react-feather";

import { getContentTranslations } from "@shared/contentful/translations.utils";
import { ELegalTranslations } from "./legal.query";
import styles from "./Legal.module.scss";
import { IAssetCflData } from "@components/ContentfulComponents/ComponentAsset/type";
import { ITranslationKeyCflData } from "@components/ContentfulComponents/ComponentTranslationKey/type";
import { IContentBlockCflData } from "@components/ContentfulComponents/BlockContentBlock/type";
import PaymentIcons from "@components/Legal/PaymentIcons/PaymentIcons.component";
import { isAsset } from "@shared/contentful/contentful.utils";

interface IProps {
	content?: IContentBlockCflData<ITranslationKeyCflData | IAssetCflData>;
}

const PaymentOptions = ({ content }: IProps) => {
	if (!content?.contentCollection?.items?.length) return null;

	const paymentOptionImages = content.contentCollection.items.filter((item) => isAsset(item));
	const securePaymentText = getContentTranslations<string>(content.contentCollection.items)?.[
		ELegalTranslations.TranslationSecurePayments
	];

	return (
		<div className={styles.securePayment} data-testid="PaymentOptions_wrapper">
			<p data-testid="PaymentOptions_secure-payment">
				<Lock size={16} /> {securePaymentText}
			</p>
			<PaymentIcons assets={paymentOptionImages} />
		</div>
	);
};

export default PaymentOptions;
