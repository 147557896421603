import { useContext } from "react";
import { isEmpty } from "lodash";
import ProductThumbnail from "@components/Cart/CartProductCard/ProductThumbnail/ProductThumbnail.component";
import styles from "@components/Cart/CartProductCard/CartProductCard.module.scss";
import SearchProductInfo from "./SearchProductInfo.component";
import { TAutocompleteItem } from "./types";
import getConfig from "next/config";
import { appendUrlHashParam } from "@utils/url.utils";
import { LayoutContext } from "@components/Layout/Layout.context";
import { handleSuggestionsProductCardClick } from "@components/Header/Components/Search/Search.utils";
import { buildSearchSuggestionDataLayer } from "@shared/datalayer/search-suggestion-datalayer/search-suggestion-datalayer";
import { globalDataLayer } from "@shared/datalayer/DataLayer";

const {
	publicRuntimeConfig: { host },
} = getConfig();

export interface ISearchProductCard {
	item: TAutocompleteItem;
	locale: string;
	isQuerySuggestionEmptyCount: boolean;
	search: string;
	count: number;
	className?: string;
}

const SearchProductCard = ({
	item,
	locale,
	className = "search",
	isQuerySuggestionEmptyCount,
	search,
	count,
}: ISearchProductCard) => {
	const { setSearchBarFocused } = useContext(LayoutContext);

	if (isEmpty(item)) return null;
	const {
		model_id,
		price: finalPrice,
		priceLabel,
		discountPercentage,
		slashedPrice,
		__autocomplete_queryID: queryID,
	} = item;

	const price = {
		finalPrice: finalPrice || 0,
		priceLabel: priceLabel || "",
		discountPercentage: discountPercentage || 0,
		slashedPrice: slashedPrice || 0,
		showSlash: false,
		//TODO remove mrp from IPriceData? It is only used in mocks
		mrp: finalPrice || 0,
	};

	const brand = item[`manufacturer_${locale}` as unknown as keyof TAutocompleteItem] as string;
	const itemName = item[`name_${locale}` as unknown as keyof TAutocompleteItem] as string;
	const thumbnail = item[
		`image_link_small_${locale}` as unknown as keyof TAutocompleteItem
	] as string;
	const productSlug = item[`link_${locale}` as unknown as keyof TAutocompleteItem];
	const url = `${host}/${locale}/p/${model_id}/${productSlug}`;
	const linkToProduct = queryID ? appendUrlHashParam(url, { queryID }) : url;

	const handleClick = () => {
		const searchSuggestion = buildSearchSuggestionDataLayer({
			term: search,
			count,
			type: "product",
			url: linkToProduct,
		});

		globalDataLayer.customDataLayer.dispatchData(searchSuggestion);
		handleSuggestionsProductCardClick(model_id, setSearchBarFocused);
	};

	return (
		<div
			className={`${styles.wrapper} ${styles[className]} ${
				isQuerySuggestionEmptyCount ? styles.searchQueryProductsOnly : ""
			}`}
			data-testid="SearchProductCard_wrapper"
		>
			<div className={styles.cardWrapper}>
				<ProductThumbnail
					className={className}
					thumbnailUrl={thumbnail}
					brand={brand}
					itemName={itemName}
					linkToProduct={linkToProduct}
					isFirstCartElement={false}
					handleClick={handleClick}
				/>

				<SearchProductInfo
					className={className}
					name={itemName}
					brand={brand}
					price={price}
					linkToProduct={linkToProduct}
					handleClick={handleClick}
				/>
			</div>
		</div>
	);
};

export default SearchProductCard;
