import getConfig from "next/config";
import { IProps } from "./LinkArrow.types";
import LinkHref from "./LinkHref.component";
import LinkNext from "./LinkNext.component";

const {
	publicRuntimeConfig: { host },
} = getConfig();

const LinkArrow = (props: IProps) => {
	return props.url.includes(host) ? <LinkNext {...props} /> : <LinkHref {...props} />;
};

export default LinkArrow;
