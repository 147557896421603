import React, { ReactNode } from "react";
import { BLOCKS } from "@contentful/rich-text-types";

import {
	ELegalTranslations,
	ILegalVariables,
	legalQuery,
	TLegalData,
	TLegalDataItems,
} from "./legal.query";
import LegalLink from "./LegalLink.component";
import PaymentOptions from "./PaymentOptions.component";

import { filterLegalLinks, findPaymentOptionsContent } from "@components/Legal/legal.utils";
import { IRichTextCflData } from "@components/ContentfulComponents/ComponentRichText/type";
import { TRichTextEntryNode } from "@shared/contentful/contentful.types";
import useGraphQL from "@hooks/useGraphql";
import { renderRichTextWithLinks } from "@shared/contentful/contentful-render.utils";
import { getContentTranslations } from "@shared/contentful/translations.utils";

import styles from "./Legal.module.scss";

const additionalRenderNodeOptions = {
	[BLOCKS.PARAGRAPH]: (_node: TRichTextEntryNode, children: ReactNode) => (
		<p className={styles.copyrightWrapper} data-testid="Legal_copyright">
			{children}
		</p>
	),
};

interface IProps {
	isLightFooter?: boolean;
}

const Legal = ({ isLightFooter }: IProps) => {
	const { data } = useGraphQL<TLegalData, ILegalVariables>(legalQuery, {
		variables: { identifier: ELegalTranslations.BlockLegal, limit: 1 },
	});

	const legalCollectionItems = data?.contentBlockCollection?.items?.[0]?.contentCollection?.items;

	if (!legalCollectionItems?.length) return null;

	const translations = getContentTranslations<TLegalDataItems>(legalCollectionItems);

	const copyright = translations?.[ELegalTranslations.TextCopyright] as
		| undefined
		| IRichTextCflData;

	const legalLinks = filterLegalLinks(translations);

	return (
		<div
			className={`container-grid medium-grid ${styles.legalWrapper} ${
				isLightFooter ? styles.lightFooter : styles.regularFooter
			}`}
			data-testid="Legal_wrapper"
		>
			{isLightFooter && (
				<PaymentOptions content={findPaymentOptionsContent(legalCollectionItems)} />
			)}
			{copyright &&
				renderRichTextWithLinks({
					richTextDocument: copyright.richTextContent?.json,
					openLinksInANewTab: copyright.openLinksInANewTab,
					additionalRenderNodeOptions,
				})}
			{legalLinks?.length ? (
				<div className={styles.linksWrapper} data-testid="Legal_links">
					{legalLinks.map((link) => (
						<LegalLink link={link} key={link?.sys?.id} />
					))}
				</div>
			) : null}
		</div>
	);
};

export default Legal;
