import { gql } from "graphql-request";
import { componentTranslationKeyFragment } from "@components/ContentfulComponents/ComponentTranslationKey/fragment";

export const layoutDataQuery = gql`
	${componentTranslationKeyFragment}
	query layoutData($identifier: String, $locale: String) {
		contentBlockCollection(where: { identifier: $identifier }, locale: $locale) {
			items {
				contentCollection {
					items {
						...translationKey
					}
				}
			}
		}
	}
`;
