import React from "react";

import { ILevelProps } from "@components/Menu/menu.types";

import MenuLevelLink from "./MenuLevelLink/MenuLevelLink.component";
import MenuLink from "./MenuLink/MenuLink.component";
import { ILinkCflData } from "@components/ContentfulComponents/ComponentLink/type";
import { IMenuHeaderCflData } from "@components/ContentfulComponents/BlockMegaMenu/type";

const MenuItem = ({
	content,
	updateContent,
	...rest
}: ILevelProps<ILinkCflData | IMenuHeaderCflData>) => {
	const { url } = content as ILinkCflData;

	return url ? (
		<MenuLink {...(content as ILinkCflData)} style={rest.style} />
	) : (
		<MenuLevelLink
			content={content as IMenuHeaderCflData}
			updateContent={updateContent}
			{...rest}
		/>
	);
};

export default MenuItem;
