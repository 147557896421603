import React from "react";
import { getFirstNameInCookies } from "@shared/profile/cookies.utils";
import { isUserNameValid } from "./menu-footer.utils";
import { useAbsolute } from "@hooks/useAbsolute";
import { getTextEllipsis } from "@utils/format.utils";

interface IProps {
	translation: string;
}

const MenuFooterLoggedIn = ({ translation }: IProps) => {
	const userName = getFirstNameInCookies();

	const isValid = isUserNameValid(userName);

	const { absoluteUrl } = useAbsolute("/my-account");

	return <a href={absoluteUrl}>{isValid ? getTextEllipsis(userName, 10) : translation}</a>;
};

export default MenuFooterLoggedIn;
