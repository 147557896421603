export const scrollToTarget = (anchor = 0) => {
	window.scrollTo({ top: anchor, behavior: "smooth" });
};

export const scrollToTargetById = (id: string) => {
	const element = document.getElementById(id);
	if (!element) return;

	element.scrollIntoView({ behavior: "smooth", block: "start" });
};

export const OFFSET_TOP_VALUE = 56;
export const OFFSET_FILTERS_HEIGHT = 80;
