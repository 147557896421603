export const isSameDate = (d1: Date, d2: Date) => {
	return (
		d1.getDate() === d2.getDate() &&
		d1.getMonth() === d2.getMonth() &&
		d1.getFullYear() === d2.getFullYear()
	);
};

export const incrementDateHours = (hours?: number, d = new Date()) => {
	d.setHours(d.getHours() + (hours ?? 0));
	return d;
};

export const isYearMonthDay = (date: string) => {
	const regex = /^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12]\d|3[01])$/;
	return Boolean(regex.exec(date));
};

export const zeroTimeFromDate = (d: Date) => {
	if (!d) return;
	return new Date(d.getFullYear(), d.getMonth(), d.getDate());
};

export const shouldDisplayHoursOrDate = (hours?: number) => {
	if (!hours) return;
	const currentDate = new Date();
	const deliveryDate = incrementDateHours(hours);
	const currentDateWithoutTime = zeroTimeFromDate(currentDate);
	const deliveryDateWithoutTime = zeroTimeFromDate(deliveryDate);

	return deliveryDateWithoutTime &&
		currentDateWithoutTime &&
		deliveryDateWithoutTime > currentDateWithoutTime
		? deliveryDate
		: `${hours}`;
};

export const convertDayAndHoursIntoDate = (day: string, hours?: string) => {
	const dateTimeStr = `${day}T${hours || "00:00"}:00Z`;
	const utcDate = new Date(dateTimeStr);
	return new Date(
		utcDate.toLocaleString("en-US", {
			timeZone: hours ? Intl.DateTimeFormat().resolvedOptions().timeZone : "UTC",
		})
	);
};
