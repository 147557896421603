import { useSession } from "next-auth/react";
import React, { forwardRef, ForwardRefRenderFunction } from "react";

import LangSwitcher from "@components/LangSwitcher/LangSwitcher.component";
import Login from "@components/Login/Login.component";
import { ITranslationKeyCflData } from "@components/ContentfulComponents/ComponentTranslationKey/type";
import { useChangeLocale } from "@hooks/useLocale";
import { getContentTranslations } from "@shared/contentful/translations.utils";

import MenuFooterLoggedIn from "./MenuFooterLoggedIn.component";
import { EMenuFirstQueryIdentifier } from "../menu.types";

import styles from "./MenuFooter.module.scss";

interface IProps {
	footerData: ITranslationKeyCflData[];
	showShadow: boolean;
}

const MenuFooter: ForwardRefRenderFunction<HTMLDivElement, IProps> = (
	{ footerData, showShadow }: IProps,
	ref
) => {
	const changeLang = useChangeLocale();

	const { data: session } = useSession();

	if (!footerData?.length) return null;

	const translations = getContentTranslations<string>(footerData);

	if (!translations) {
		return null;
	}

	const langSwitcher = translations[EMenuFirstQueryIdentifier.TranslationLangSwitcher];
	const login = translations[EMenuFirstQueryIdentifier.TranslationFooterLogin];
	const myAccount = translations[EMenuFirstQueryIdentifier.TranslationFooterMyAccount];

	const menuFooterClass = showShadow
		? `${styles.menuFooter} ${styles.menuFooterShadow}`
		: styles.menuFooter;

	return (
		<div ref={ref} className={menuFooterClass} data-testid="MenuFooter_wrapper">
			<LangSwitcher changeLang={changeLang}>{langSwitcher}</LangSwitcher>
			{session ? <MenuFooterLoggedIn translation={myAccount} /> : <Login>{login}</Login>}
		</div>
	);
};

export default forwardRef(MenuFooter);
