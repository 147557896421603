import { forwardRef, ForwardRefRenderFunction, InputHTMLAttributes, ReactNode } from "react";
import CheckboxRadio from "./CheckboxRadio/CheckboxRadio.component";
import Field from "./Field/Field.component";

interface IInputElement extends InputHTMLAttributes<HTMLInputElement> {
	errorMessage?: string;
}
export interface IFieldElement extends IInputElement {
	type: string;
	caption?: string;
	label: string;
}
export interface ICheckElement extends IInputElement {
	type: "radio" | "checkbox";
	label: string | ReactNode;
}

export type InputProps = IFieldElement | ICheckElement;

const IsCheckElement = (elementProps: InputProps): elementProps is ICheckElement => {
	return elementProps.type === "radio" || elementProps.type === "checkbox";
};

const Input: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (props, ref) => {
	if (IsCheckElement(props)) {
		return <CheckboxRadio {...props} ref={ref} />;
	} else {
		return <Field {...props} ref={ref} />;
	}
};

export default forwardRef(Input);
