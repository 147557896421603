import { gql } from "graphql-request";

import { componentTranslationKeyFragment } from "@components/ContentfulComponents/ComponentTranslationKey/fragment";

export enum EScrollToTopIdentifier {
	TextBackToTop = "text_back-to-top",
}

export interface IScrollToTopVariables {
	identifier: EScrollToTopIdentifier;
}

export const scrollToTopQuery = gql`
	${componentTranslationKeyFragment}
	query scrollToTop($identifier: String, $locale: String) {
		translationKeyCollection(where: { identifier: $identifier }, locale: $locale, limit: 1) {
			items {
				...translationKey
			}
		}
	}
`;
