import { gql } from "graphql-request";

import { IContentBlockCflData } from "@components/ContentfulComponents/BlockContentBlock/type";
import { componentLinkFragment } from "@components/ContentfulComponents/ComponentLink/fragment";
import { ILinkCflData } from "@components/ContentfulComponents/ComponentLink/type";
import { IContentBlockCollection } from "@shared/contentful/contentful.types";

export enum EFooterLinksIdentifiers {
	BlockFooterLinks = "block_footer-links",
}

export interface IFooterLinksVariables {
	identifier: EFooterLinksIdentifiers;
}

export type TFooterLinksData = IContentBlockCollection<IContentBlockCflData<ILinkCflData>>;

export const footerLinksQuery = gql`
	${componentLinkFragment}
	query footerLinks($identifier: String, $locale: String) {
		contentBlockCollection(where: { identifier: $identifier }, locale: $locale, limit: 1) {
			items {
				identifier
				contentCollection {
					items {
						... on ContentBlock {
							__typename
							sys {
								id
							}
							identifier
							label
							contentCollection {
								items {
									...link
								}
							}
						}
					}
				}
			}
		}
	}
`;
