import { capitalize } from "lodash";
import React from "react";

import { ILinkCflData } from "@components/ContentfulComponents/ComponentLink/type";
import Icon, { EIcon } from "@components/Icon/Icon.component";
import { useRelOptionsFollowHome } from "@hooks/useFollowHome";
import { convertTargetOptions } from "@shared/contentful/contentful.utils";

interface IProps {
	link: ILinkCflData;
}

const SocialMediaLink = ({ link }: IProps) => {
	const { url, icon, relOptions, targetOptions } = link;
	const rel = useRelOptionsFollowHome(relOptions);

	if (!icon) {
		return null;
	}

	const linkIcon = EIcon[capitalize(icon) as keyof typeof EIcon];

	const target = convertTargetOptions(targetOptions);

	return (
		<a
			href={url}
			className={icon}
			target={target}
			rel={rel}
			data-testid="SocialMediaLink_cta-link"
			title={icon}
		>
			<Icon
				icon={linkIcon}
				size={20}
				noHover
				noActive
				data-testid={`${icon}-icon`}
				aria-label={icon}
			/>
		</a>
	);
};

export default SocialMediaLink;
