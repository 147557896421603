import { forwardRef, ForwardRefRenderFunction } from "react";
import { IFieldElement } from "../Input.component";
import styles from "./Field.module.scss";

const Field: ForwardRefRenderFunction<HTMLInputElement, IFieldElement> = (
	{ label, id, errorMessage, caption, ...rest },
	ref
) => {
	const htmlFor = id || label;
	const className = `${styles.floatLabelContainer} ${errorMessage ? styles.error : ""}`;
	rest.placeholder = rest.placeholder || label;

	return (
		<div className={className} data-testid="Field_container">
			<input id={htmlFor} {...rest} ref={ref} />
			<label htmlFor={htmlFor}>{label}</label>
			{caption && <figcaption>{caption}</figcaption>}
			{errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
		</div>
	);
};

export default forwardRef(Field);
