import Icon, { EIcon } from "../Icon.component";
import styles from "./IconCart.module.scss";

interface IIconCart {
	cartCount: number;
	ariaLabel?: string;
}

const IconCart = ({ cartCount, ariaLabel }: IIconCart) => {
	return (
		<span className={styles.iconWithBadgeWrapper}>
			{cartCount > 0 && (
				<span data-testid="IconCart_badge-count" className={styles.badge}>
					{cartCount}
				</span>
			)}
			<Icon icon={EIcon.Cart} aria-label={ariaLabel} />
		</span>
	);
};
export default IconCart;
