import React from "react";
import { isEmpty } from "lodash";

import Button from "@components/Button/Button.component";
import { EButtonSize, EButtonType } from "@components/Button/types";
import { ILinkCflData } from "@components/ContentfulComponents/ComponentLink/type";
import { useAbsolute } from "@hooks/useAbsolute";
import { convertRelOptions, convertTargetOptions } from "@shared/contentful/contentful.utils";
import { replaceAllBreakSpaces } from "@shared/text/text.utils";

import FooterLinkLi from "./FooterLinkLi.component";
import { IFooterLinkProps } from "./FooterLinks.type";

import styles from "./FooterLinks.module.scss";

interface IProps {
	isSmallScreen: boolean;
	links: ILinkCflData[];
}

const FooterLinkMiscLi = ({ data }: IFooterLinkProps) => {
	const { url, label, relOptions, targetOptions } = data;
	const rel = convertRelOptions(relOptions);
	const target = convertTargetOptions(targetOptions);
	const { absoluteUrl } = useAbsolute(url);

	if (!absoluteUrl) return null;

	return (
		<li data-testid="FooterLinkMiscLi_misc-list">
			<Button
				buttonType={EButtonType.Secondary}
				as={"a"}
				buttonSize={EButtonSize.Lg}
				href={absoluteUrl}
				rel={rel}
				target={target}
			>
				{replaceAllBreakSpaces(label)}
			</Button>
		</li>
	);
};

const MiscLinks = ({ isSmallScreen, links }: IProps) => {
	return (
		<ul className={styles.buttonsWrapper}>
			{links?.map((link) => {
				if (!link || isEmpty(link)) return null;
				return isSmallScreen ? (
					<FooterLinkMiscLi data={link} key={link.label} />
				) : (
					<FooterLinkLi data={link} key={link.label} isBold />
				);
			})}
		</ul>
	);
};

export default MiscLinks;
