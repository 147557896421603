interface ISportAZItem {
	letter: string;
	sports: string[];
}

export const fakeSportsList: ISportAZItem[] = [
	{ letter: "a", sports: ["aquagym"] },
	{ letter: "b", sports: ["basket", "badminton", "ballet"] },
	{ letter: "c", sports: ["curling", "climbing", "canoeing", "contemporary dance"] },
	{ letter: "d", sports: ["downhill skiing", "deep diving"] },
	{ letter: "e", sports: ["extreme skiing", "english pool"] },
	{
		letter: "f",
		sports: ["flamenco", "fitness dance", "fitness", "fishing", "football", "frisbee", "fencing"],
	},
	{ letter: "h", sports: ["horseback trail riding", "high jump"] },
	{ letter: "i", sports: ["ice diving", "indoor golf"] },
	{ letter: "j", sports: ["judo"] },
	{ letter: "k", sports: ["kickboxing", "kite"] },
	{ letter: "l", sports: ["long drive", "lifesaving", "luge"] },
	{ letter: "m", sports: ["moutiain running", "marathon"] },
];
