import { componentRichTextFragment } from "@components/ContentfulComponents/ComponentRichText/fragment";
import { componentTranslationKeyFragment } from "@components/ContentfulComponents/ComponentTranslationKey/fragment";
import { gql } from "graphql-request";

export enum EGeolocation {
	BlockGeolocation = "header_geoloc",
	TranslationDeliveryLabel = "header_geoloc-delivery-and-pick-up-for",
	TranslationChooseLocation = "header_geoloc-choose-your-location",
	TranslationEnterPostalCode = "header_geoloc-enter-your-postal-code",
	TranslationSavePostalCode = "header_geoloc-save-postal-code",
	TranslationTermsAndPolicy = "header_geoloc-terms-and-policy",
	TranslationPostalCodeError = "header_geoloc-postal-code-error",
}

export const geolocationQuery = gql`
	${componentTranslationKeyFragment}
	${componentRichTextFragment}
	query geolocationQuery($identifier: String, $locale: String) {
		contentBlockCollection(where: { identifier: $identifier }, locale: $locale) {
			items {
				contentCollection {
					items {
						...translationKey
						...richText
					}
				}
			}
		}
	}
`;
