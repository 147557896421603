import { getAlgoliaResults } from "@algolia/autocomplete-js";
import { SearchClient } from "algoliasearch";
import { RefObject } from "react";

interface IGetSourcesData {
	sourceId: string;
	searchClient: SearchClient;
	indexName: string;
	inputRef: RefObject<HTMLInputElement>;
	hitsPerPage: number;
}

export const getSourcesData = ({
	sourceId,
	searchClient,
	indexName,
	inputRef,
	hitsPerPage,
}: IGetSourcesData) => ({
	sourceId,
	getItems({ query }: { query: string }) {
		return getAlgoliaResults({
			searchClient,
			queries: [
				{
					indexName,
					query: inputRef?.current?.value || query,
					params: {
						clickAnalytics: true,
						hitsPerPage,
					},
				},
			],
		});
	},
});
