import LinkArrow from "../LinkArrow/LinkArrow.component";
import { EFooterSeoIdentifiers, footerSeoTextQuery, IFooterSeoVariables } from "./footer-seo.query";
import { useAbsolute } from "@hooks/useAbsolute";
import useGraphQL from "@hooks/useGraphql";
import { ITextWithLinkCflData } from "@components/ContentfulComponents/ComponentTextWithLink/type";
import { ILinkCflData } from "@components/ContentfulComponents/ComponentLink/type";
import { convertRelOptions, convertTargetOptions } from "@/shared/contentful/contentful.utils";
import { replaceAllBreakSpaces } from "@shared/text/text.utils";
import { renderRichTextWithLinks } from "@shared/contentful/contentful-render.utils";

import styles from "./FooterSEOText.module.scss";

interface IProps {
	link: ILinkCflData;
}

export interface ITextWithLinkCollection {
	textWithLinkCollection: {
		items: ITextWithLinkCflData[];
	};
}

const FooterLink = ({ link }: IProps) => {
	const { url, label, targetOptions, relOptions } = link || {};
	const target = convertTargetOptions(targetOptions);
	const rel = convertRelOptions(relOptions);
	const { absoluteUrl } = useAbsolute(url);

	return (
		<>
			{absoluteUrl && (
				<LinkArrow
					url={absoluteUrl}
					target={target}
					rel={rel}
					label={label}
					size="16"
					coloredWhite
				/>
			)}
		</>
	);
};

const FooterSEOText = () => {
	const { data: footerSeoData } = useGraphQL<ITextWithLinkCollection, IFooterSeoVariables>(
		footerSeoTextQuery,
		{
			variables: { identifier: EFooterSeoIdentifiers.FooterSeo },
		}
	);

	if (!footerSeoData?.textWithLinkCollection?.items?.[0]) {
		return null;
	}

	const { title, link, richTextContent } = footerSeoData.textWithLinkCollection.items[0];

	return (
		<div className={styles.footerSEOTextWrapper} data-testid="FooterSEOText_wrapper">
			{title && (
				<p className={styles.title}>
					<strong>{replaceAllBreakSpaces(title)}</strong>
				</p>
			)}
			{richTextContent?.json && renderRichTextWithLinks({ richTextDocument: richTextContent.json })}
			{link && <FooterLink link={link} />}
		</div>
	);
};

export default FooterSEOText;
