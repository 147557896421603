import { isEmpty } from "lodash";
import { IPriceProps } from "./ProductPrice.types";
import ClearancePrice from "@components/ProductInfo/ProductPrice/ClearancePrice.component";
import FinalPrice from "@components/ProductInfo/ProductPrice/FinalPrice.component";

const ProductPrice = ({ price, className = "", labelPriceDrop, modelId }: IPriceProps) => {
	if (isEmpty(price)) return null;

	const isClearancePrice = price.finalPrice < price.slashedPrice;

	return (
		<>
			{isClearancePrice || labelPriceDrop ? (
				<ClearancePrice
					price={price}
					className={className}
					labelPriceDrop={labelPriceDrop}
					modelId={modelId}
				/>
			) : (
				<FinalPrice price={price} className={className} />
			)}
		</>
	);
};
export default ProductPrice;
