import React from "react";
import { isEmpty } from "lodash";

import { useResponsive } from "@hooks/useDevice";

import { ILinkCflData } from "@components/ContentfulComponents/ComponentLink/type";
import { IContentBlockCflData } from "@components/ContentfulComponents/BlockContentBlock/type";

import MiscLinks from "./MiscLinks.component";
import ExpandableLinks from "./ExpandableLinks.component";

import styles from "./FooterLinks.module.scss";

interface IFooterLinkGroupProps {
	groupLinks: IContentBlockCflData<ILinkCflData>;
}

const FooterLinksGroup = ({ groupLinks }: IFooterLinkGroupProps) => {
	const { isMobile, isTablet } = useResponsive();

	if (!groupLinks) return null;

	const { contentCollection, label: groupLabel } = groupLinks;

	const linksContent = contentCollection?.items;

	if (isEmpty(linksContent)) return null;

	const Misc = "Misc";
	const isSmallScreen = isMobile || isTablet;
	const isMisc = groupLabel === Misc;

	return (
		<div
			className={`${styles.groupWrapper} group${groupLabel}`}
			data-testid="FooterLinksGroup_wrapper"
		>
			{isMisc ? (
				<MiscLinks links={linksContent} isSmallScreen={isSmallScreen} />
			) : (
				<ExpandableLinks links={linksContent} isSmallScreen={isSmallScreen} label={groupLabel} />
			)}
		</div>
	);
};

export default FooterLinksGroup;
