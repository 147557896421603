import { useEffect, useRef, useState } from "react";

export const useScroll = (scrollBegin = 0) => {
	const [showScroll, setShowScroll] = useState(false);

	const checkScroll = () => {
		if (!showScroll && window.scrollY > scrollBegin) {
			setShowScroll(true);
		} else if (showScroll && window.scrollY <= scrollBegin) {
			setShowScroll(false);
		}
	};

	useEffect(() => {
		window.addEventListener("scroll", checkScroll);
		return () => {
			window.removeEventListener("scroll", checkScroll);
		};
	});

	return showScroll;
};

export const useScrollTop = () => {
	const [isScrollTop, setIsScrollTop] = useState(false);
	const precedentScroll = useRef(0);

	const checkScrollTop = () => {
		if (!isScrollTop && window.scrollY < precedentScroll.current) {
			setIsScrollTop(true);
		} else if (isScrollTop && window.scrollY > precedentScroll.current) {
			setIsScrollTop(false);
		}

		precedentScroll.current = window.scrollY;
	};

	useEffect(() => {
		window.addEventListener("scroll", checkScrollTop);
		return () => {
			window.removeEventListener("scroll", checkScrollTop);
		};
	});

	return isScrollTop;
};

export const useScrolling = (scrollTo: number) => {
	const [isScrolling, setIsScrolling] = useState(true);

	const requestRef = useRef(0);

	const checkScrollEnd = () => {
		if (window.scrollY !== scrollTo) {
			requestRef.current = window.requestAnimationFrame(checkScrollEnd);
		} else {
			setIsScrolling(false);
		}
	};

	useEffect(() => {
		requestRef.current = window.requestAnimationFrame(checkScrollEnd);
		return () => {
			window.cancelAnimationFrame(requestRef.current);
		};
	}, []);

	return isScrolling;
};
