import Link from "next/link";
import { IProps } from "./LinkArrow.types";
import LinkLabel from "./LinkLabel.component";

import styles from "./LinkArrow.module.scss";

const LinkNext = ({ url, label, size, target, rel, coloredWhite }: IProps) => {
	return (
		<Link
			href={url}
			target={target}
			rel={rel}
			className={`${styles.linkWrapper} ${coloredWhite ? styles.whiteTextLinkWrapper : ""}`}
			data-testid="LinkNext_cta-link"
		>
			<LinkLabel label={label} size={size} />
		</Link>
	);
};

export default LinkNext;
