import {
	defineVideoOptions,
	EVideoOptions,
} from "@components/ContentfulComponents/ComponentAsset/utils/asset.utils";

export interface IVideo {
	assetUrl?: string | null;
	className?: string;
	videoOptions?: EVideoOptions[] | null;
}

const Video = ({ assetUrl, className, videoOptions }: IVideo) => {
	if (!assetUrl) return null;

	const { autoplayVideo, showControls } = defineVideoOptions(videoOptions);

	return (
		<video
			controls={showControls}
			controlsList="nodownload"
			autoPlay={autoplayVideo}
			muted
			className={className ?? ""}
			data-testid="Video_video"
		>
			<source src={assetUrl} />
		</video>
	);
};

export default Video;
