import React from "react";
import { useSelector } from "react-redux";

import { IPropsButtonMenu } from "../type";

import { ELayoutIdentifiers } from "@components/Layout/type";
import Icon, { EIcon } from "@components/Icon/Icon.component";
import Menu from "@components/Menu/Menu.component";
import { selectTranslationStore } from "@redux/translation/translation.selector";

import styles from "./ButtonMenu.module.scss";

const ButtonMenu = ({ menu, toggleMenu, displayMenu, ...props }: IPropsButtonMenu) => {
	const translations = useSelector(selectTranslationStore);
	const ariaIconMenu = translations[ELayoutIdentifiers.AriaIconMenu] || "open menu";

	return (
		<>
			<button
				{...props}
				className={`${props.className} ${styles.buttonMenu}`}
				data-testid="ButtonMenu_open-btn"
				onClick={() => toggleMenu({ isDisplay: true })}
				aria-label={ariaIconMenu}
			>
				<Icon icon={EIcon.Menu} aria-label={ariaIconMenu} />
			</button>
			{menu && <Menu toggleMenu={toggleMenu} displayMenu={displayMenu} content={menu} />}
		</>
	);
};

export default ButtonMenu;
