export const handleSelectionRange = (
	selectionStart: number | null,
	isRemoveKeyPressed: boolean,
	inputRef: React.RefObject<HTMLInputElement | null>,
	cursorIndexes: number[]
) => {
	if (selectionStart !== null) {
		const selectionStartValue =
			cursorIndexes.includes(selectionStart) && !isRemoveKeyPressed
				? selectionStart + 1
				: selectionStart;
		inputRef.current?.setSelectionRange(selectionStartValue, selectionStartValue);
	}
};
