import { forwardRef, ForwardRefRenderFunction } from "react";
import { ICheckElement } from "../Input.component";
import styles from "./CheckboxRadio.module.scss";

const CheckboxRadio: ForwardRefRenderFunction<HTMLInputElement, ICheckElement> = (
	{ label, className, ...rest },
	ref
) => {
	const { disabled } = rest;
	const classNameContainer = className
		? `${styles.checkContainer} ${className}`
		: styles.checkContainer;

	return (
		<div className={classNameContainer}>
			<label className={disabled ? styles.labelDisabled : ""}>
				{label}
				<input ref={ref} {...rest} />
			</label>
		</div>
	);
};

export default forwardRef(CheckboxRadio);
