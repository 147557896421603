import { componentLinkFragment } from "@components/ContentfulComponents/ComponentLink/fragment";
import { componentTranslationKeyFragment } from "@components/ContentfulComponents/ComponentTranslationKey/fragment";
import { gql } from "graphql-request";

export const accountSubmenuQuery = gql`
	${componentTranslationKeyFragment}
	${componentLinkFragment}
	query accountSubmenu($identifier: String, $locale: String) {
		contentBlockCollection(where: { identifier: $identifier }, locale: $locale) {
			items {
				contentCollection {
					items {
						...translationKey
						...link
					}
				}
			}
		}
	}
`;
