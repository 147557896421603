export enum EVideoOptions {
	Autoplay = "autoplay",
	ShowControls = "show controls",
}

export const defineVideoOptions = (videoOptions?: EVideoOptions[] | null) => {
	const showControls = videoOptions?.includes(EVideoOptions.ShowControls);
	const autoplayVideo = videoOptions?.includes(EVideoOptions.Autoplay);

	return { autoplayVideo, showControls };
};

export const cleanPixlId = (id: string | null) => {
	return id?.replace("ref:", "");
};
