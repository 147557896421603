import { MenuContext } from "@components/Header/Default/Header.component";
import { menuSecondQuery } from "@components/Header/Default/menu.query";
import { ILevelTwoGQL } from "@components/Menu/menu.types";
import useGraphQL from "@hooks/useGraphql";
import { memo, useContext } from "react";
import { IPropsComponent } from "../type";
import { useResponsive } from "@hooks/useDevice";
import { IMenuHeaderCflData } from "@components/ContentfulComponents/BlockMegaMenu/type";
import { replaceAllBreakSpaces } from "@shared/text/text.utils";
import PostalCodePicker from "@components/Geolocation/PostalCodePicker/PostalCodePicker.component";
import styles from "@components/Header/Header.module.scss";

interface IPropsMenuHeader extends IPropsComponent {
	data: IMenuHeaderCflData[];
	isCartScreen: boolean;
}

type TListMenu = Pick<IMenuHeaderCflData, "label" | "isHighlighted"> & Record<"id", string>;

const ListMenu = ({ label, isHighlighted, id }: TListMenu) => {
	const toggleMenu = useContext(MenuContext);

	const { data } = useGraphQL<ILevelTwoGQL>(menuSecondQuery, {
		variables: { id },
	});

	const handleClick = () => {
		toggleMenu({
			isDisplay: true,
			content: data && { data: data.grpMenuSection?.contentCollection?.items, label },
		});
	};

	return (
		<li>
			<button className={isHighlighted ? styles.isHighlighted : ""} onClick={handleClick}>
				{replaceAllBreakSpaces(label)}
			</button>
		</li>
	);
};

const MenuHeader = ({ className = "", data, isCartScreen }: IPropsMenuHeader) => {
	const { isDesktop, isBigScreen } = useResponsive();
	const isScreenDesktop = isBigScreen || isDesktop;

	if (!data || !isScreenDesktop) return null;

	return (
		<ul className={className}>
			{data.map(({ label, isHighlighted, sys }) => {
				return (
					label && (
						<ListMenu key={sys?.id} label={label} isHighlighted={isHighlighted} id={sys?.id} />
					)
				);
			})}
			{!isCartScreen && <PostalCodePicker />}
		</ul>
	);
};

export default memo(MenuHeader);
