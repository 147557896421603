import useGraphQL from "@hooks/useGraphql";
import {
	EPanelNotConnectedIdentifiers,
	panelNotConnectedQuery,
	TPanelNotConnected,
} from "./PanelNotConnected.query";
import ConnectionAdvantages from "@components/Header/Components/PanelNotConnected/ConnectionAdvantages/ConnectionAdvantages.component";
import Asset from "@components/ContentfulComponents/ComponentAsset/Asset.component";
import { EBlockTypenames, EComponentTypenames } from "@shared/contentful/contentful.types";

import styles from "@components/Header/Components/PanelNotConnected/PanelNotConnected.module.scss";

const PanelNotConnected = () => {
	const { data } = useGraphQL<TPanelNotConnected>(panelNotConnectedQuery, {
		variables: {
			identifier: EPanelNotConnectedIdentifiers.NotConnectedPanel,
		},
	});

	const collectionItems = data?.contentBlockCollection?.items?.[0];

	if (!collectionItems) return null;

	return (
		<div className={styles.contentWrapper} data-testid="PannelNotConnected_wrapper">
			{collectionItems.contentCollection?.items?.map((item) => {
				switch (item.__typename) {
					case EComponentTypenames.Asset:
						return <Asset data={item} key={item.sys?.id} className={styles.asset} />;
					case EBlockTypenames.ContentBlock:
						return <ConnectionAdvantages data={item} key={item.sys?.id} />;
					default:
						return null;
				}
			})}
		</div>
	);
};

export default PanelNotConnected;
