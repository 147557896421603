import React, { ReactNode, useEffect, useMemo, useState } from "react";
import ModalCenter from "@components/ModalCenter/ModalCenter.components";
import { TContentTranslation } from "@shared/types/translations.types";
import PostalCode from "@components/Input/PostalCode/PostalCode.component";
import Button from "@components/Button/Button.component";
import { EButtonSize, EButtonType } from "@components/Button/types";
import Loader from "@components/Loader/Loader.component";
import styles from "./PostalCodeModal.module.scss";
import { EGeolocation } from "../geolocation.query";
import { renderRichTextWithLinks } from "@shared/contentful/contentful-render.utils";
import { IRichTextCflData } from "@components/ContentfulComponents/ComponentRichText/type";
import { TRichTextEntryNode } from "@shared/contentful/contentful.types";
import { BLOCKS } from "@contentful/rich-text-types";
import { useDispatch } from "react-redux";
import { updateUserGeolocation } from "@redux/misc/misc.reducer";
import { isRejected } from "@reduxjs/toolkit";
import { AppDispatch } from "@redux/store";
import { selectTranslation } from "@shared/contentful/translations.utils";
import { RichTextGenericElement } from "@shared/contentful/contentful.utils";
import { formatPostalCode } from "@utils/postalCode.utils";

const additionalRenderNodeOptions = {
	[BLOCKS.PARAGRAPH]: (_node: TRichTextEntryNode, children: ReactNode) => (
		<RichTextGenericElement Tag="p" className={styles.richText}>
			{children}
		</RichTextGenericElement>
	),
};

interface IPostalCodeModalProps {
	setShowModal: () => void;
	showModal: boolean;
	translations: TContentTranslation<string | IRichTextCflData>;
	postalCode: string;
}

const PostalCodeModal = ({
	setShowModal,
	showModal,
	translations,
	postalCode,
}: IPostalCodeModalProps) => {
	const [postalCodeValue, setPostalCodeValue] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [isValid, setIsValid] = useState(false);
	const [error, setError] = useState("");
	const dispatch: AppDispatch = useDispatch();
	const formattedPostalCode = useMemo(() => formatPostalCode(postalCode), [postalCode]);

	useEffect(() => {
		if (showModal) {
			setPostalCodeValue(formattedPostalCode);
		}
	}, [showModal]);

	const handleClick = async () => {
		setIsLoading(true);
		const response = await dispatch(updateUserGeolocation(postalCodeValue));

		isRejected(response)
			? setError(selectTranslation({ key: EGeolocation.TranslationPostalCodeError, translations }))
			: setShowModal();

		setIsLoading(false);
	};

	const richTextContent = translations[EGeolocation.TranslationTermsAndPolicy] as IRichTextCflData;
	const isSamePostalCode = postalCodeValue === formattedPostalCode;

	return (
		<ModalCenter
			toggle={setShowModal}
			showModal={showModal}
			panelName={selectTranslation({ key: EGeolocation.TranslationChooseLocation, translations })}
			modalCenterContentStyle={styles.modalCenterContent}
		>
			<ModalCenter.CloseButton handleCloseModal={setShowModal} showModal={showModal} />
			<ModalCenter.Content>
				<h2 className={styles.title}>
					{selectTranslation({ key: EGeolocation.TranslationChooseLocation, translations })}
				</h2>
				<p className={styles.description}>
					{selectTranslation({ key: EGeolocation.TranslationEnterPostalCode, translations })}
				</p>
				<PostalCode
					errorMessage={error}
					value={postalCodeValue}
					setPostalCodeValue={setPostalCodeValue}
					postalCodeValidator={{ setIsValid }}
					setErrorMessageApi={setError}
					className={styles.input}
				/>
				<Button
					className={styles.button}
					buttonType={EButtonType.Primary}
					as="button"
					onClick={handleClick}
					buttonSize={EButtonSize.Lg}
					disabled={isLoading || !isValid || isSamePostalCode}
					data-testid="PostalCode_save"
				>
					{isLoading ? (
						<Loader />
					) : (
						selectTranslation({ key: EGeolocation.TranslationSavePostalCode, translations })
					)}
				</Button>
				{renderRichTextWithLinks({
					richTextDocument: richTextContent?.richTextContent?.json,
					openLinksInANewTab: richTextContent?.openLinksInANewTab,
					additionalRenderNodeOptions,
				})}
			</ModalCenter.Content>
		</ModalCenter>
	);
};

export default PostalCodeModal;
