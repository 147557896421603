import {
	EScrollToTopIdentifier,
	IScrollToTopVariables,
	scrollToTopQuery,
} from "./ScrollToTop.query";

import useGraphQL from "@hooks/useGraphql";
import { TContentTranslation } from "@shared/types/translations.types";
import { getContentTranslations } from "@shared/contentful/translations.utils";
import { ITranslationKeyCollection } from "@shared/contentful/contentful.types";
import { scrollToTarget } from "@utils/scrollToTarget.utils";

import styles from "./ScrollToTop.module.scss";

const ScrollToTopMobile = () => {
	const handlerClick = () => {
		scrollToTarget();
	};

	const { data } = useGraphQL<ITranslationKeyCollection, IScrollToTopVariables>(scrollToTopQuery, {
		variables: { identifier: EScrollToTopIdentifier.TextBackToTop },
	});

	if (!data?.translationKeyCollection?.items?.length) return null;

	const translations: TContentTranslation<string> = getContentTranslations(
		data.translationKeyCollection.items
	);

	const scrollToTopTranslation = translations?.[EScrollToTopIdentifier.TextBackToTop];

	if (!scrollToTopTranslation) return null;

	return (
		<div className={styles.scrollToTopMobileWrapper} data-testid="ScrollToTopMobile_wrapper">
			<button onClick={handlerClick} data-testid="ScrollToTopMobile_btn">
				{scrollToTopTranslation}
			</button>
		</div>
	);
};

export default ScrollToTopMobile;
