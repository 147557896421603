import { Dispatch, SetStateAction } from "react";

import { IMenuHeaderCflData } from "@components/ContentfulComponents/BlockMegaMenu/type";
import { ILinkCflData } from "@components/ContentfulComponents/ComponentLink/type";
import { ITranslationKeyCflData } from "@components/ContentfulComponents/ComponentTranslationKey/type";
import { ICollection, IContentCollection, ISysCfl } from "@/shared/contentful/contentful.types";

// graphql
export enum EMenuFirstQueryIdentifier {
	MenuMainMenu = "menu_main-menu",
	MenuContentFooterMenu = "menu-content_footer-menu",
	MenuContentSportsList = "menu-content_sports-list",
	TranslationFooterMyAccount = "translation_footer-my-account",
	TranslationFooterLogin = "translation_footer-login",
	TranslationLangSwitcher = "translation_lang-switcher",
}

export enum EMenuTypename {
	MenuSection = "GrpMenuSection",
}

export interface IMenuFirstQueryIdentifier {
	identifier: EMenuFirstQueryIdentifier;
	footerIdentifier: EMenuFirstQueryIdentifier;
}

export type TMenuLevel = IContentCollection<ILinkCflData | IMenuHeaderCflData> &
	ISysCfl & {
		identifier?: string | null;
		label?: string;
		__typename?: EMenuTypename.MenuSection;
	};

export interface ILevelProps<T> {
	content: T;
	updateContent: (content: TMenuLevel[], label: string | undefined) => void;
	currentLevel?: number;
	label?: string;
	lastLabel?: string;
	closeAndReset: () => void;
	classNameLevel: string;
	itemStyle?: string;
	style?: { animationDelay: string };
	hasLevel3?: boolean;
	setIsMenuLoaded?: Dispatch<SetStateAction<boolean>>;
}

export interface ILevelTwoGQL {
	grpMenuSection: IContentCollection<TMenuLevel>;
}

type TMenuQueryDataCollection = {
	identifier?: string;
} & IContentCollection<TMenuLevel>;

interface IFooterMenuQueryDataCollection extends IContentCollection<ITranslationKeyCflData> {
	identifier?: string;
}

export interface IMenuGQL {
	megaMenuCollection: ICollection<TMenuQueryDataCollection>;
	footerMenuCollection: ICollection<IFooterMenuQueryDataCollection>;
}
//menu
export interface IDisplayMenu {
	isDisplay: boolean;
	content?: { data: TMenuLevel[]; label: string };
}
