import React, { ReactNode, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import Beak from "../Beak/Beak.component";
import styles from "./Panel.module.scss";
import { colors } from "@shared/styles/theme";

interface PropsPanel {
	children: ReactNode;
	rootId: string;
	classNameWrapper?: string;
}

const Panel = ({ children, rootId, classNameWrapper }: PropsPanel) => {
	const [container, setContainer] = useState<HTMLElement | undefined>();

	useEffect(() => {
		setContainer(document.getElementById(rootId) ?? document.body);
	});

	if (!container) return null;

	return createPortal(
		<div className={`${styles.panelWrapper} ${styles.panelPortal} ${classNameWrapper}`}>
			<Beak fillColor={colors.white} />
			<div className={styles.panelGroupWrapper}>{children}</div>
		</div>,
		container
	);
};

export default Panel;
