import { RootState } from "@redux/reducer";
import { createSelector } from "reselect";

export const selectCartStore = (store: RootState) => store.cart;

export const selectCartIsLoading = createSelector(selectCartStore, (cart) => cart.isLoading);
export const selectCartItemsCount = createSelector(selectCartStore, (cart) => cart.itemsCount);
export const selectCartLatestAddedProduct = createSelector(
	selectCartStore,
	(cart) => cart.latestAddedProduct
);
