import React from "react";
import Icon, { EIcon } from "../Icon/Icon.component";
import styles from "./FooterLinks.module.scss";
import { colors } from "@shared/styles/theme";

interface IProps {
	label: string | undefined;
	handler: () => void;
}

const FooterLinksTitleMobile = ({ label, handler }: IProps) => {
	if (!label) return null;
	return (
		<button className={styles.toggleButton} onClick={handler}>
			<div className={styles.groupName} data-testid="FooterLinksTitleMobile_wrapper">
				<strong>{label}</strong>
				<Icon icon={EIcon.Plus} noHover noActive color={colors.white} />
			</div>
		</button>
	);
};

export default FooterLinksTitleMobile;
