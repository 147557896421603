import { EVendor } from "@shared/cookie-consent/cookie-consent.utils";
import { Dispatch, SetStateAction } from "react";
import aa from "search-insights";
import getConfig from "next/config";
import { IDidomiObject } from "@didomi/react";
import { ECookieName, deleteCookie, isCookieExisting } from "@utils/cookie.utils";

const {
	publicRuntimeConfig: { algoliaAppId, algoliaApiKey },
} = getConfig();

export const handleSuggestionsProductCardClick = (
	modelId: number,
	setSearchBarFocused?: Dispatch<SetStateAction<boolean>>
) => {
	const regex = /\/p\/(\d+)\/[^/]+$/;
	const urlModelId = regex.exec(window.location.pathname)?.[1];
	`${modelId}` === urlModelId && setSearchBarFocused?.(false);
};

export const initAlgolia = (shouldUseCookie?: boolean) => {
	const useCookie = shouldUseCookie ?? isCookieExisting(ECookieName.AlgoliaUserToken);
	aa("init", {
		appId: algoliaAppId,
		apiKey: algoliaApiKey,
		useCookie,
	});
};

export const validateAlgoliaConsent = () => {
	window.didomiOnReady?.push((Didomi: IDidomiObject) => {
		if (Didomi.isConsentRequired()) {
			Didomi.getObservableOnUserConsentStatusForVendor(`c:${EVendor.Algolia}`).subscribe(
				(consentStatusForVendor: boolean) => {
					if (!consentStatusForVendor) {
						//TODO refacto to suppress this one
						deleteCookie(ECookieName.AlgoliaUserToken);
						initAlgolia();
					} else {
						initAlgolia(consentStatusForVendor);
					}
				}
			);
		}
	});
};
