import React from "react";

import Media from "@components/ContentfulComponents/ComponentAsset/Media/Media.component";
import YoutubeVideo from "@components/ContentfulComponents/ComponentAsset/YoutubeVideo/YoutubeVideo.component";
import PixlVideo from "@components/ContentfulComponents/ComponentAsset/PixlVideo/PixlVideo.component";

import { IAssetCflData } from "@components/ContentfulComponents/ComponentAsset/type";
import { EImageSlot } from "@components/CustomImage/custom-images.types";

import styles from "@components/ContentfulComponents/ComponentAsset/asset.module.scss";

interface IProps {
	data: IAssetCflData;
	imageSlot?: { mobile: EImageSlot | string; desktop: EImageSlot | string };
	className?: string;
	loading?: boolean;
	priority?: boolean;
}

const Asset = ({ data, imageSlot, className, loading, priority }: IProps) => {
	if (!data) return null;

	const { asset, assetDesktop, identifier, diffusionPixlId, videoOptions, videoTitle, videoUrl } =
		data;

	if (!asset && !diffusionPixlId && !videoUrl) return null;

	if (asset)
		return (
			<Media
				asset={asset}
				assetDesktop={assetDesktop}
				videoOptions={videoOptions}
				className={className}
				imageSlot={imageSlot}
				loading={loading}
				priority={priority}
			/>
		);

	if (diffusionPixlId) {
		return (
			<PixlVideo
				diffusionPixlId={diffusionPixlId}
				className={`${styles.videoWrapper} ${className}`}
				videoOptions={videoOptions}
				videoTitle={videoTitle}
				key={identifier}
			/>
		);
	}

	return (
		<YoutubeVideo
			assetUrl={videoUrl}
			videoTitle={videoTitle}
			className={`${styles.videoWrapper} ${className}`}
			videoOptions={videoOptions}
			key={identifier}
		/>
	);
};

export default Asset;
