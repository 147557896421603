import { replaceAllBreakSpaces } from "@shared/text/text.utils";
import { Labelprops } from "./LinkArrow.types";

import styles from "./LinkArrow.module.scss";

const LinkLabel = ({ label, size }: Labelprops) => (
	<>
		<span className={styles.icon} data-testid="LinkLabel_arrow">
			►
		</span>
		<span className={`${styles.label} text-${size}`} data-testid="LinkLabel_label">
			{replaceAllBreakSpaces(label)}
		</span>
	</>
);

export default LinkLabel;
