import useLocale from "@hooks/useLocale";
import { IQuerySuggestions } from "./types";
import styles from "@components/Algolia/Autocomplete/Autocomplete.module.scss";
import SearchProductCard from "./SearchProductCard.component";
import { ELayoutIdentifiers } from "@components/Layout/type";
import { useSelector } from "react-redux";
import { selectTranslationStore } from "@redux/translation/translation.selector";

export const SearchProductCardTitle = () => {
	const translations = useSelector(selectTranslationStore);
	return (
		<p className="aa-SourceHeaderTitle" id="searchQueryProductsList">
			{translations[ELayoutIdentifiers.TitleSearchQueryProducts]}
		</p>
	);
};

const SearchQueryProducts = ({ autocompleteState, results }: IQuerySuggestions) => {
	const { locale } = useLocale();
	if (
		!autocompleteState?.isOpen ||
		!autocompleteState.collections?.length ||
		autocompleteState.query?.length < 3
	)
		return null;
	const querySuggestionEmpty = autocompleteState.collections.filter(
		(collection) => collection?.items?.length === 0
	);

	const isQuerySuggestionEmptyCount = querySuggestionEmpty.length > 0;
	return (
		<>
			{autocompleteState.collections?.map(({ source, items }) => {
				if (!source || source.sourceId !== "queryProducts" || !items?.length) return;
				return (
					<div
						key={source.sourceId}
						className="aa-Source"
						data-testid="SearchQueryProducts_wrapper"
					>
						<SearchProductCardTitle />

						<ul
							aria-labelledby="searchQueryProductsList"
							className={`aa-List ${
								isQuerySuggestionEmptyCount ? styles.searchQueryProductsOnly : ""
							}`}
						>
							{items.map((item) => (
								<SearchProductCard
									item={item}
									locale={locale}
									key={item?.model_id}
									isQuerySuggestionEmptyCount={isQuerySuggestionEmptyCount}
									search={autocompleteState.query}
									count={results}
								/>
							))}
						</ul>
					</div>
				);
			})}
		</>
	);
};
export default SearchQueryProducts;
