import React from "react";

import { ILinkCflData } from "@components/ContentfulComponents/ComponentLink/type";
import { useAbsolute } from "@hooks/useAbsolute";
import { convertRelOptions, convertTargetOptions } from "@shared/contentful/contentful.utils";
import { replaceAllBreakSpaces } from "@shared/text/text.utils";

import styles from "./MenuLink.module.scss";

const MenuLink = ({ url, label, targetOptions, relOptions, style }: ILinkCflData) => {
	const target = convertTargetOptions(targetOptions);
	const rel = convertRelOptions(relOptions);

	const { absoluteUrl } = useAbsolute(url);

	if (!absoluteUrl) return null;

	return (
		<li className={`${styles.isLink}`} data-testid="MenuLink_wrapper">
			<a
				href={absoluteUrl}
				rel={rel}
				target={target}
				tabIndex={0}
				className={`${styles.btnUpdateLevel}  ${styles.fadeIn}`}
				style={style}
			>
				{replaceAllBreakSpaces(label)}
			</a>
		</li>
	);
};

export default MenuLink;
