import { EComponentTypenames, ISysCfl } from "@shared/contentful/contentful.types";

export enum EComponentLinkType {
	Primary = "primary",
	Secondary = "secondary",
}

export interface ILinkCflData extends ISysCfl {
	__typename: EComponentTypenames;
	identifier: string;
	url: string;
	label: string;
	relOptions?: string[];
	targetOptions?: string;
	icon?: string;
	type?: EComponentLinkType;
	style?: React.CSSProperties;
}
