import getConfig from "next/config";
import Head from "next/head";
import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { ELayoutIdentifiers, ILayoutProps, ILayoutVariables } from "./type";
import { layoutDataQuery } from "./layout.query";
import { LayoutContext } from "./Layout.context";

import Header from "@components/Header/Default/Header.component";
import Footer from "@components/Footer/Footer.component";
import { ITranslationKeyCflData } from "@components/ContentfulComponents/ComponentTranslationKey/type";
import Preview from "@components/Preview/Preview.component";
import useLocale from "@hooks/useLocale";
import useGraphQL from "@hooks/useGraphql";
import { setTranslation } from "@redux/translation/translation.reducer";
import { IContentBlockCollection } from "@shared/contentful/contentful.types";
import { getContentTranslations } from "@shared/contentful/translations.utils";
import { globalDataLayer } from "@shared/datalayer/DataLayer";
import { buildLayoutDataLayer } from "@shared/datalayer/layout-datalayer/layout-datalayer";
import { t } from "@utils/translations.utils";
import { removeTrailingSlash, deleteUrlParameter } from "@utils/url.utils";
import { useResponsive } from "@hooks/useDevice";
import { useSession } from "next-auth/react";
import { buildDataLayerLoggedUser } from "@shared/datalayer/user-datalayer/user-datalayer.utils";
import { useRouter } from "next/router";

const {
	publicRuntimeConfig: { host },
} = getConfig();

interface ITranslationContext {
	translatedPath?: string;
}

export const TranslationContext = createContext<ITranslationContext>({
	translatedPath: "",
});

const Layout = ({
	children,
	metas,
	gtmBreadcrumbs,
	pageType,
	currentUrl,
	images,
	finalPrice,
	canonicalUrl,
	openGraphTitle,
	robots = "index,follow",
	region,
	additionalDataLayerInfo,
	preview,
	hideReinsurance,
}: ILayoutProps) => {
	const { locale } = useLocale();
	const defaultAlternate = "/";
	const [pathName, setPathName] = useState(defaultAlternate);
	const [dataLayerPage, setDataLayerPage] = useState<IPageDataLayer | undefined>();
	const { searchBarFocused } = useContext(LayoutContext);
	const { isMobile, isTablet } = useResponsive();
	const isSmallScreen = isMobile || isTablet;

	const canonical = canonicalUrl ?? currentUrl ?? "";
	const dispatch = useDispatch();
	const session = useSession();

	const { data: layoutData } = useGraphQL<
		IContentBlockCollection<ITranslationKeyCflData>,
		ILayoutVariables
	>(layoutDataQuery, {
		variables: { identifier: ELayoutIdentifiers.BlockLayoutAriaLabels },
	});

	useEffect(() => {
		const regex = new RegExp(`^(/${locale}/)|(/${locale}$)`);
		setPathName(window.location.pathname.replace(regex, "/"));

		setDataLayerPage(
			buildLayoutDataLayer({
				canonical,
				pageType,
				region,
				gtmBreadcrumbs,
				locale,
				additionalInfo: additionalDataLayerInfo,
			})
		);

		globalDataLayer.customDataLayer.subscribe();
	}, []);

	useEffect(() => {
		if (session && session.status !== "loading") {
			if (session.status === "authenticated") {
				globalDataLayer.customDataLayer.dispatchData(buildDataLayerLoggedUser(session.data));
			}
			globalDataLayer.customDataLayer.dispatchDataAndPageReady({
				page: dataLayerPage,
			});
		}
	}, [session?.status]);

	useEffect(() => {
		if (layoutData)
			dispatch(
				setTranslation(
					getContentTranslations(
						layoutData.contentBlockCollection?.items[0]?.contentCollection?.items
					)
				)
			);
	}, [layoutData]);

	let translatedPath = metas?.fr;

	if (locale === "fr") {
		translatedPath = metas?.en;
	}

	const { page } = useRouter().query;
	const contextValue = useMemo(() => ({ translatedPath }), []);

	if (page) robots = "noindex,follow";

	return (
		<TranslationContext.Provider value={contextValue}>
			<Head>
				<title>{metas?.metaTitle}</title>
				<meta name="description" content={metas?.metaDescription ?? ""} />
				<meta name="viewport" content="initial-scale=1.0, width=device-width" />
				<link rel="icon" href="/favicon.ico" sizes="any" />
				{!page && (
					<link
						rel="canonical"
						href={deleteUrlParameter(canonical)}
						data-testid="Layout_canonical-link"
					/>
				)}
				<link
					rel="alternate"
					href={`${host}/fr${removeTrailingSlash(metas?.fr || pathName)}`}
					hrefLang="fr-ca"
					data-testid="Layout_alternate-link-fr"
				/>
				<link
					rel="alternate"
					href={`${host}/en${removeTrailingSlash(metas?.en || pathName)}`}
					hrefLang="en-ca"
					data-testid="Layout_alternate-link-en"
				/>

				<meta name={"robots"} content={robots} />
				{metas?.isMetaGeneral && (
					<>
						<meta
							property="og:type"
							content={metas?.metaType || "website"}
							data-testid="Layout_metatags-og-type"
						/>
						<meta property="og:url" content={currentUrl} data-testid="Layout_metatags-og-url" />
						<meta
							property="og:title"
							content={openGraphTitle || metas?.metaTitle}
							data-testid="Layout_metatags-og-meta-title"
						/>
						<meta
							property="og:site_name"
							content={t("decathlon country", locale)}
							data-testid="Layout_metatags-og-site-name"
						/>
						<meta
							property="og:description"
							content={metas?.metaDescription}
							data-testid="Layout_metatags-og-meta-description"
						/>
					</>
				)}
				{metas?.isMetaPriceImage && (
					<>
						{images?.[0]?.url && (
							<meta
								property="og:image"
								content={images?.[0]?.url}
								data-testid="Layout_metatags-og-image"
							/>
						)}
						{finalPrice && (
							<meta
								property="product:price:amount"
								content={finalPrice.toString()}
								data-testid="Layout_metatags-og-price-amount"
							/>
						)}
						<meta
							property="product:price:currency"
							content="CAD"
							data-testid="Layout_metatags-og-price-currency"
						/>
					</>
				)}
			</Head>

			<Header />
			{isSmallScreen && searchBarFocused ? null : children}
			{preview && <Preview />}
			{isSmallScreen && searchBarFocused ? null : <Footer hideReinsurance={hideReinsurance} />}
		</TranslationContext.Provider>
	);
};

export default Layout;
