import { gql } from "graphql-request";

import { blockMenuLevelFragment } from "@components/ContentfulComponents/BlockMenuLevel/fragment";
import { componentLinkFragment } from "@components/ContentfulComponents/ComponentLink/fragment";
import { componentTranslationKeyFragment } from "@components/ContentfulComponents/ComponentTranslationKey/fragment";

export const menuFirstQuery = gql`
	${componentLinkFragment}
	${componentTranslationKeyFragment}
	${blockMenuLevelFragment}
	query megaMenuCollection($identifier: String!, $footerIdentifier: String!, $locale: String!) {
		megaMenuCollection(where: { identifier: $identifier }, locale: $locale, limit: 1) {
			items {
				identifier
				contentCollection {
					items {
						... on GrpMenuSection {
							sys {
								id
							}
							contentCollection {
								items {
									... on GrpMenuSection {
										identifier
										label
										isHighlighted
										sys {
											id
										}
									}
									...link
								}
							}
						}
					}
				}
			}
		}
		footerMenuCollection: grpMenuSectionCollection(
			where: { identifier: $footerIdentifier }
			locale: $locale
			limit: 1
		) {
			items {
				...grpMenuSection
			}
		}
	}
`;

export const menuSecondQuery = gql`
	${componentLinkFragment}
	${componentTranslationKeyFragment}
	${blockMenuLevelFragment}
	query level2($id: String!, $locale: String!) {
		grpMenuSection(id: $id, locale: $locale) {
			contentCollection(limit: 6) {
				items {
					...grpMenuSection
				}
			}
		}
	}
`;
