import React from "react";
import styles from "./PostalCodePicker.module.scss";
import Icon, { EIcon } from "@components/Icon/Icon.component";
import { colors } from "@shared/styles/theme";
import { geolocationQuery, EGeolocation } from "../geolocation.query";
import PostalCodeModal from "../PostalCodeModal/PostalCodeModal.component";
import useToggle from "@hooks/useToggle";
import { IRichTextCflData } from "@components/ContentfulComponents/ComponentRichText/type";
import { ITranslationKeyCflData } from "@components/ContentfulComponents/ComponentTranslationKey/type";
import useGraphQL from "@hooks/useGraphql";
import { IContentBlockCollection } from "@shared/contentful/contentful.types";
import { getContentTranslations, selectTranslation } from "@shared/contentful/translations.utils";
import useGeolocation from "@hooks/useGeolocation";
import { formatPostalCode } from "@utils/postalCode.utils";

const PostalCodePicker = () => {
	const [showModal, setShowModal] = useToggle(false);
	const { data: geolocTranslations } = useGraphQL<
		IContentBlockCollection<ITranslationKeyCflData | IRichTextCflData>
	>(geolocationQuery, {
		variables: { identifier: EGeolocation.BlockGeolocation },
	});

	const userGeolocation = useGeolocation();
	if (!userGeolocation) return null;

	const translations = getContentTranslations<string | IRichTextCflData>(
		geolocTranslations?.contentBlockCollection?.items?.[0]?.contentCollection?.items
	);

	return (
		<div className={styles.wrapper}>
			<p>
				{selectTranslation({
					key: EGeolocation.TranslationDeliveryLabel,
					translations,
				})}
			</p>
			<button className={styles.cta} onClick={() => setShowModal()}>
				{formatPostalCode(userGeolocation.postalCode)}
				<Icon icon={EIcon.Edit} size={16} color={colors.primary} noHover />
			</button>
			{userGeolocation.postalCode && (
				<PostalCodeModal
					showModal={showModal}
					setShowModal={setShowModal}
					translations={translations}
					postalCode={userGeolocation.postalCode}
				/>
			)}
		</div>
	);
};

export default PostalCodePicker;
