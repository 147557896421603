import React, { ReactEventHandler, ReactNode, useRef } from "react";
import { useSelector } from "react-redux";
import { selectTranslationStore } from "@redux/translation/translation.selector";
import { signIn } from "next-auth/react";
import { useAbsolute } from "@hooks/useAbsolute";
import { useResponsive } from "@hooks/useDevice";
import { ELayoutIdentifiers } from "@components/Layout/type";
import PanelNotConnected from "@components/Header/Components/PanelNotConnected/PanelNotConnected.component";
import { EDataLayerEventType } from "@shared/datalayer/datalayer.types";
import { dispatchDataLayerEvent } from "@shared/datalayer/DataLayer";
import { handlePanelTransitionEnd } from "@components/Login/Login.utils";

import styles from "@components/Login/Login.module.scss";

export interface ILoginProps {
	children: ReactNode;
	className?: string;
	onClick?: () => void;
	isHeader?: boolean;
}

const Login = ({ children, className, onClick, isHeader = false }: ILoginProps) => {
	const { absoluteUrl: loginAliasUrl } = useAbsolute("/signin");
	const translations = useSelector(selectTranslationStore);
	const panelWrapperRef = useRef<HTMLDivElement>(null);
	const isLoginCalled = useRef(false);

	const { isDesktop, isBigScreen } = useResponsive();
	const isLargeScreen = isDesktop || isBigScreen;

	const handleLogin: ReactEventHandler<HTMLAnchorElement> = (e) => {
		e.preventDefault();

		if (isLoginCalled.current) return;

		isLoginCalled.current = true;

		dispatchDataLayerEvent(EDataLayerEventType.MenuLoginClick);
		onClick?.();
		signIn("auth0");
	};

	return (
		<div className={styles.loginWrapper}>
			<a
				href={loginAliasUrl}
				data-testid="Login_link"
				className={className}
				aria-label={translations[ELayoutIdentifiers.AriaAccountLogin]}
				onClick={handleLogin}
			>
				{children}
			</a>
			{isLargeScreen && isHeader && (
				<>
					<span className={styles.hoverGap}></span>
					<div
						className={styles.notConnectedWrapper}
						ref={panelWrapperRef}
						onTransitionEnd={() => handlePanelTransitionEnd(panelWrapperRef.current)}
						data-testid="Login_not-connected-wrapper"
					>
						<PanelNotConnected />
					</div>
				</>
			)}
		</div>
	);
};

export default Login;
