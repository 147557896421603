import React, { ReactNode, UIEventHandler } from "react";
import { useSelector } from "react-redux";

import { ELayoutIdentifiers } from "@components/Layout/type";
import Icon, { EIcon } from "@components/Icon/Icon.component";
import PanelSlide, { EDirection } from "@components/PanelSlide/PanelSlide.component";
import { useResponsiveOnLoad } from "@hooks/useDevice";
import { selectTranslationStore } from "@redux/translation/translation.selector";

import styles from "./ModalCenter.module.scss";

export interface IModalContentProps {
	children: ReactNode;
	classNameContentContainer?: string;
	onScroll?: UIEventHandler<HTMLDivElement>;
}

const Content = ({ children, classNameContentContainer, onScroll }: IModalContentProps) => {
	return (
		<div
			className={classNameContentContainer}
			onScroll={onScroll}
			data-testid="ModalCenter_content"
			id="ModalCenter_content"
		>
			{children}
		</div>
	);
};

export interface IModalCloseButtonProps {
	children?: ReactNode;
	showModal: boolean;
	handleCloseModal: () => void;
}

interface IHandleClick {
	handleCloseModal: () => void;
	shouldClose: boolean;
}

export const handleClick = ({ handleCloseModal, shouldClose }: IHandleClick) => {
	if (shouldClose) {
		handleCloseModal();
	}
};

const CloseButton = ({ children, showModal, handleCloseModal }: IModalCloseButtonProps) => {
	const translations = useSelector(selectTranslationStore);
	const ariaIconClose = translations[ELayoutIdentifiers.AriaIconCloseModal] || "close modal";

	if (!children) {
		return (
			<div className={styles.closeButtonContainer}>
				<button
					onClick={() => handleClick({ handleCloseModal, shouldClose: showModal })}
					data-testid="ModalCenter_default-close-btn"
					aria-label={ariaIconClose}
				>
					<Icon icon={EIcon.Close} aria-label={ariaIconClose} />
				</button>
			</div>
		);
	}
	return <>{children}</>;
};

export interface IModalCenterProps {
	children: ReactNode;
	showModal: boolean;
	toggle: () => void;
	panelName: string;
	modalCenterWrapperStyle?: string;
	modalCenterContentStyle?: string;
	initialFocus?: HTMLElement | SVGElement | false;
}

const ModalCenter = ({
	children,
	showModal,
	toggle,
	panelName,
	modalCenterWrapperStyle = "",
	modalCenterContentStyle = "",
	initialFocus,
}: IModalCenterProps) => {
	const { isLoad, devices } = useResponsiveOnLoad();
	const isTabletOrMobile = devices.isTablet || devices.isMobile;

	if (!isLoad) {
		return null;
	}

	return (
		<PanelSlide
			position={isTabletOrMobile ? EDirection.Bottom : EDirection.Center}
			isOpen={showModal}
			toggleOpen={toggle}
			classNameWrapper={`${modalCenterWrapperStyle} ${styles.modalCenterWrapper}`}
			classNameContent={`${modalCenterContentStyle} ${styles.modalCenterContent}`}
			panelName={panelName}
			initialFocus={initialFocus}
		>
			{children}
		</PanelSlide>
	);
};

ModalCenter.Content = Content;
ModalCenter.CloseButton = CloseButton;

export default ModalCenter;
