import { Dispatch, RefObject, SetStateAction, UIEvent } from "react";

interface IMenuFullyDisplayed {
	panelRef: RefObject<HTMLDivElement>;
	headerRef: RefObject<HTMLDivElement>;
	menuRef: RefObject<HTMLDivElement>;
	footerRef: RefObject<HTMLDivElement>;
}

export const displayShadowOnScroll = (
	e: UIEvent<HTMLElement>,
	setShowShadow: Dispatch<SetStateAction<boolean>>
) => {
	if (!setShowShadow) return;

	const isBottom =
		Math.abs(
			e.currentTarget.scrollHeight - e.currentTarget.scrollTop - e.currentTarget.clientHeight
		) < 1;

	setShowShadow(!isBottom);
};

export const isMenuFullyDisplayed = ({
	panelRef,
	headerRef,
	menuRef,
	footerRef,
}: IMenuFullyDisplayed): boolean => {
	if (!menuRef || !headerRef || !panelRef || !footerRef) return false;

	const screenHeight = screen.height;
	const menuHeight = menuRef?.current?.offsetHeight;
	const navStyle =
		menuRef?.current?.firstChild && getComputedStyle(menuRef.current.firstChild as Element);
	const navPaddingBottom = navStyle?.paddingBottom?.slice(0, -2);
	const headerHeight = headerRef?.current?.offsetHeight;
	const panelStyle = panelRef?.current && getComputedStyle(panelRef.current);
	const panelRowGap = panelStyle?.rowGap?.slice(0, -2);
	const footerStyle =
		footerRef?.current?.firstChild && getComputedStyle(footerRef.current.firstChild as Element);
	const footerHeight = footerStyle?.height?.slice(0, -2);

	if (headerHeight && menuHeight && footerHeight && panelRowGap && navPaddingBottom) {
		return (
			screenHeight >= headerHeight + menuHeight + +footerHeight + +panelRowGap - +navPaddingBottom
		);
	}
	return false;
};
